<template>
    <div>
        <md-tabs md-dynamic-height :md-active-tab="dropdownType" v-on:md-changed="e => $emit('update:dropdown-type', e)">
            <md-tab id="list" md-label="List">
                <draggable v-model="list" handle=".bars">
                    <div v-for="(item) of list" :key="item.key" class="horizontal-wrapper">
                        <div class="icon bars"
                            :style="`flex: 0 0 15pt; cursor:pointer; margin: 10pt; background-image: url(${require('../assets/bars-solid.svg')});`">
                        </div>

                        <md-field>
                            <label for="">name</label>
                            <md-input v-model="item.name" @blur="fieldValidation"></md-input>
                        </md-field>
                        <div class="icon" v-on:click="deleteOption(item)"
                            :style="`flex: 0 0 15pt; cursor:pointer; margin: 10pt; background-image: url(${require('../assets/trash-can-solid.svg')});`">
                        </div>
                    </div>

                </draggable>
                <div class="horizontal-wrapper">
                    <div></div>
                    <md-button class="md-primary md-raised"
                        v-on:click="addOptions">add
                    </md-button>
                    <div></div>
                </div>
            </md-tab>
            <md-tab id="comboField" md-label="Daten">
                <md-field>
                    <label>Daten</label>
                    <md-select :value="combofieldId" v-on:md-selected="selectComboField">
                        <md-option v-for="cf in comboFields" :key="cf.key" :value="cf.id">{{ cf.name }}</md-option>
                    </md-select>
                </md-field>

                <data-set-editor-dialog></data-set-editor-dialog>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>

import draggable from 'vuedraggable'
import dataSetEditorDialogVue from './data-set-editor-dialog.vue';

export default {
    name: 'dropdown-source',
    props: { 'list': Array, 'url': String, 'combofield-id': [String, Number], 'dropdown-type':String},
    components: {
        draggable,
        'data-set-editor-dialog': dataSetEditorDialogVue
    },
    methods:{
        fieldValidation(){
            console.log('blur');
        },
        addOptions(){
            this.itemList.push({ id: this.$uuid.v4(), name: 'new Option', key: this.$uuid.v4() })
            this.$emit('update:list', this.itemList)
        },
        deleteOption(item){
            this.$emit('update:list', this.itemList.filter(e => e.key != item.key))
        },
        selectComboField(e){
            console.log(e);
            this.$emit('update:combofield-id', e)

        }
    },
    watch:{
        list(){
            const list = this.$props.list
            list.forEach(e => e.key = this.$uuid.v4())
            this.itemList = list;
        }
    },
    computed:{
        comboFields(){
            return this.$store.getters.getComboFields
        }
    },
    data(){
        const list = this.$props.list
        list.forEach(e => e.key = this.$uuid.v4());
        return {
            itemList: list
        }
    }, updated(){
       console.log('update') 
    }
}
</script>
