<template>
    <div class="vertical-wrapper">
        <md-table style="flex:1 0 0" 
        md-height="auto" 
        :value="searchedAndSorted" md-model-id="key" :md-sort.sync="sortAfter" :md-sort-order.sync="softAfterASC" md-card
            md-fixed-header :key="reloadString">
            <md-table-toolbar class="vertical-wrapper" style="flex: 0 0 auto" ref="toolbar">
              
                <div class="horizontal-wrapper"  style="width: 100%; flex-wrap: wrap;">
                    <div style="flex: 0 0 20pt; margin: 5pt" v-if="reportsSyncing">
                        <md-icon class="cloud-state"  :md-src="require('../../assets/circle-nodes-solid.svg')"></md-icon>

                    </div>
                        <div class="md-toolbar-section-start" style="flex: 1 0 0; margin-right: 30px">
                            <h3 class="md-title" style="text-align: left;">Berichte</h3>
                        </div>

                        <md-field style="flex: 0 0 auto; width: auto; margin-right: 10pt">
                            <label for="">Author</label>
                            <md-select v-model="authorSelector" v-on:md-selected="sortReports" :length="reportAuthors.length">
                                <md-option v-for="a in mReportAuthor()" :key="'author' + a.key" :value="a.name">{{a.name}}
                                </md-option>
                            </md-select>
                        </md-field>

                        
                        <md-field ref="date-input" style="flex: 0 0 auto; width: auto; margin-right: 10pt;" md-clearable v-on:md-clear="e => sortReports(e, true)">
                            <label for="">Reports bis</label>
                            <md-input type="text" @focus="e => $refs['date-selector'].open(e)" v-model="dateSelector" ></md-input>
                        </md-field>
                        
                        
                        <md-field style="flex: 0 0 auto; width: auto; margin-right: 10pt; flex-wrap: wrap">
                            <label for="">Formular</label>
                            <md-select v-model="formularSelector" v-on:md-selected="sortReports">
                                <md-option value="all">Alle</md-option>
                                <md-option v-for="f in formulars" :key="'form' + f.key" :value="f.name">{{f.name}}
                                </md-option>
                            </md-select>
                        </md-field>
                        
                        
                        <md-field style="flex: 0 0 auto; width: auto; margin-right: 10pt;">
                            <label for="">Status</label>
                            <md-select v-model="statusFilterSelector" v-on:md-selected="sortReports">
                                <md-option value="all">Alle</md-option>
                                <md-option v-for="opt in statusOptions" :key="opt.key" :value="opt.value">{{opt.name}}
                                </md-option>
                            </md-select>
                        </md-field>
                        
                        
                        <md-field md-clearable class="md-toolbar-section-end"  style="flex: 1 0 150px; flex-wrap: wrap">
                            <md-input placeholder="Search by identifier..." v-model="search" @input="sortReports" />
                        </md-field>
                        
                </div>
                  <div class="horizontal-wrapper" style="width: 100%;">
                        <div class="vertical-wrapper" style="flex: 0 0 auto">
                            <span> Speicher verwendet </span>
                            <span>in {{ reports.length }} Reports</span>
                        </div>
                        <p></p>
                        <div style="flex: 1 0 0; margin: auto 15pt" >
                            <md-progress-bar md-mode="determinate" class="flex: 1 0 0" :md-value="localStorageUsed * 100"></md-progress-bar>
                        </div>
                        <div class="vertical-wrapper" style="flex: 0 0 auto">
                            <span>{{ (localStorageUsed * 100).toFixed(2) }} %</span>
                            <span>{{ localStorageAmount}}</span>
                        </div>
                    </div>
                <md-progress-bar style="flex: 0 0 auto" md-mode="query"></md-progress-bar>
                
            </md-table-toolbar>



            <md-table-row slot="md-table-row" slot-scope="{item}" v-on:click="openReport(item)">
                <md-table-cell   md-numeric style="width: 50pt">
                        <md-icon class="cloud-state" :class="{'not-synced': item.notSynced}" :md-src="require('../../assets/circle-nodes-solid.svg')"></md-icon>
                </md-table-cell>

                <md-table-cell md-label="Formular" md-sort-by="name">{{item.name}}</md-table-cell>
                <md-table-cell md-label="Identifier" md-sort-by="identifier">{{item.identifier}}</md-table-cell>
                <md-table-cell md-label="Author" md-sort-by="author">{{item.author}}</md-table-cell>

                <md-table-cell md-label="Datum" md-sort-by="created">{{$moment(item.created).format('YYYY-MM-DD HH:mm')}}
                </md-table-cell>
                <md-table-cell md-label="Status" md-sort-by="status">{{translateState(item.status)}}</md-table-cell>
                <md-table-cell md-label="Attribute" md-sort-by="searchAttributes">{{item.searchAttributes}}</md-table-cell>

            </md-table-row>



        </md-table>
        <div class="horizontal-wrapper" style="flex: 0 0 auto; margin-bottom: 20pt">
            <div></div>
            <md-button class="md-raised md-primary" v-on:click="openAddReportDialog = true">add</md-button>
            <div></div>
        </div>
        <md-dialog class="vertical-wrapper" :md-active.sync="openAddReportDialog" :key="JSON.stringify(formulars)">
            <md-dialog-title>Create Report</md-dialog-title>
            <div class="vertical-wrapper" style="margin: 15pt;min-width: 100pt;  overflow-y: scroll;">
                <div v-for="f in formularsLoad()" :key="f.key" class="vertical-wrapper" style="flex: 0 0 auto">
                    <md-button class="md-primary md-raised" v-on:click="createNewReport(f)">{{f.name}}</md-button>
                </div>

            </div>
            <md-dialog-actions>
                <md-button class="md-primary" v-on:click="openAddReportDialog = false">close</md-button>
            </md-dialog-actions>
        </md-dialog>
        <datetime 
            :title="'Reports bis'" 
            type="date" 
            ref="date-selector" 
            v-model="dateValue" 
            @input="sortReports" 
            class="hide-input" 
            style="text-align: center; flex: 0 0 0;" 
            >
        </datetime>

    </div>
</template>
<script>
import md5 from 'md5'

export default {
    name: 'report-list-view',
    methods:{
        sortReports(e, dateMdClear){
            if(!this.$refs['date-selector']){
                //console.log(' called by date selector')
                return
            }
            if(this.softAfterASC == 'desc')
                this.reports.sort((a, b) => a[this.sortAfter] - b[this.sortAfter])
            else
                this.reports.sort((b, a) => a[this.sortAfter] - b[this.sortAfter])
            
            
            if(dateMdClear){
                this.dateSelector = ''
            }
            this.$store.commit('set-report-selector', {author: this.authorSelector, searchtext:this.search, formular: this.formularSelector, status:this.statusFilterSelector, date: this.dateSelector })
        },
        openReport(item){
            this.$router.push({ name: 'report-details-view', params: { id: item.IDh_form, name: item.name, reportkey: item.key }})
        },
        createNewReport(f){
            this.$router.push({ name: 'report-details-view', params: { id: f.id, name: f.name, reportkey: -1 } })
        },
        translateState(state){
            const m = this.states.find(e => e[0] == state)
            if(m)
                return m[1]
            return state
        },
        mReportAuthor(){

            return this.reportAuthors.filter(e => e.name)
        },
        formularsLoad(){
            return this.formulars
        },

        
    },
    computed:{
        token() {
            return this.$store.getters.token
        },
        searchedAndSorted:{
            get(){
                return this.reports.filter(e => (((e.identifier || e.identifier == '') && e.identifier.includes(this.search)) || e.searchAttributes && e.searchAttributes.toLowerCase().includes(this.search.toLowerCase())) && 
                    (this.dateSelector == '' || e.created >= this.dateSelector) &&

                    (this.statusFilterSelector =='all' || e.status == this.statusFilterSelector) &&
                    (this.formularSelector == 'all' || e.name == this.formularSelector) &&
                    (this.authorSelector == 'Alle' || e.author == this.authorSelector) 

                )
            },set(val){
                this.reports = val
            }
        },
        formulars(){
            return this.$store.getters.formulars
        },
        reports(){
            return this.$store.getters.reports
        },
        reportAuthors(){
            return this.$store.getters.getReportAuthors
        },
        reportAuthorsLength(){
            return this.$store.getters.getReportAuthors.length
        },
        reportSelector(){
            return this.$store.getters.getReportSelector
        },

        localStorageUsed(){
            return JSON.stringify(localStorage).length / (5 * 1024 * 1024)
        },
        localStorageAmount(){
            const size = JSON.stringify(localStorage).length

            if(size < 1024)
                return size + ' B'
            else if( size <= (1024 * 1024))
                return (size / 1024).toFixed(2) + ' kB'
            return (size / (1024 * 1024)).toFixed(2) + ' mB'
        },
        dateValue:{
            get(){
                let date = this.dateSelector == ''? this.$moment(): this.$moment(this.dateSelector + ' 12:00')
                return date.format('YYYY-MM-DDTHH:mm:ssZ')
            },
            set(v){
                if (!this.$refs['date-selector']) {
                    return
                }
                if(this.$moment(v).isValid()){
                    this.dateSelector = this.$moment(v).format('YYYY-MM-DD');
                    //this.sortReports()
                }

            }
        },
        reloadString() {

            return md5(JSON.stringify(this.reportAuthors) + this.dateSelector + this.reportsSyncing) 
        },
        reportsSyncing(){
            return this.$store.getters.getReportSyncing
        }
    },
    watch:{
        reportAuthorsLength(){
        }
    },
    sockets:{
      
     
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$store.commit('set-report-syncing', true)

            vm.$socket.emit('get-formulars', { token: vm.token })
            vm.$socket.emit('get-combo-fields', { token: vm.token })

            vm.$store.dispatch('sync-reports')

            next();
        })
    },
    data(){
        const bufferReportSelector = this.$store.getters.getReportSelector

        if(bufferReportSelector.date == undefined)
            bufferReportSelector.date = this.$moment().subtract(1, 'months').format('YYYY-MM-DD')

        return{
            search: bufferReportSelector.searchtext,
            sortAfter: 'name',
            softAfterASC: 'asc',
            statusFilterSelector: bufferReportSelector.status,
            statusOptions: [
                { name: 'Offen', value: 'open', key: this.$uuid.v4()  },
                { name: 'Zur Verrechnung', value: 'for billing', key: this.$uuid.v4() },
                { name: 'Verrechnet', value: 'billed', key: this.$uuid.v4() },


            ],
            formularSelector: bufferReportSelector.formular,
            openAddReportDialog: false,
            states:[
                ['open', 'Offen'], 
                ['closed', 'Geschlossen'],
                ['inwork', 'In Bearbeitung'],
                ['for billing', 'Zur Verrechnung'],
                ['billed', 'Verrechnet'],

            ],

            dateSelector: bufferReportSelector.date,
            authorSelector: bufferReportSelector.author
        }
    },
}
</script>
<style>
.md-table-cell-container {
    text-align: left;
}

.md-table tr:first-child{
    width: 100pt !important;
}
.md-icon.md-theme-default.md-icon-image.cloud-state:not(.not-synced) svg{
    fill: var(--md-theme-default-primary);
}
</style>