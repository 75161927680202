<template>
    <div>
        <md-button class="md-raised md-primary" v-on:click="open = true">
            Editor
        </md-button>
        <md-dialog md-dialog :md-active="open"  v-on:md-clicked-outside="close">
            <div class="horizontal-wrapper">
                <md-dialog-title>JS Code Editor</md-dialog-title>
                <md-dialog-actions>

                    <md-button class="md-primary" @click="close">Close</md-button>
                    <md-button class="md-primary" @click="save">Save</md-button>
                </md-dialog-actions>
            </div>
           
            <div class="vertical-wrapper md-dialog-content" style="min-height: 70vh; min-width: 70vw;">
                function autofill(){

                <md-field>
                    <label>Code</label>
                    <md-textarea v-model="autoFillObject.code"></md-textarea>
                </md-field>
            }
            </div>
        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'js-editor',
    props: {autoFill:[Object, Number, Boolean]},
    computed:{
        autoFillObject(){
            if(this.autoFill)
                return this.autoFill
            else    
                return this.autoFillV
        }
    },
    methods:{
        async close(){
            const { isConfirmed, isDenied } = await this.$swal.fire({
                icon: 'question', 
                title: 'Speichern?', 
                text: 'Sollen die Änderungen gesperichert werden',

                showConfirmButton: true,
                confirmButtonText: 'Ja',
                
                showDenyButton: true,
                denyButtonText: 'Nein',

                showCancelButton: true,
                cancelButtonText: 'Abbrechen'
            })
            if (isConfirmed){
                this.save()
            }else if(isDenied)
                this.open = false;
                
        },
        save(){
            this.$emit('save', this.autoFillObject)
            this.open = false
        },
      
    },
    data(){
        return{
            open: false,
            autoFillV: {type: 'js', code: ''}
        }
    }
}
</script>