import VueRouter from 'vue-router'

import loginView from '@/views/login-view.vue'
import homeView from '@/views/home-view.vue'


import formularListView from '@/views/formulars/formular-list-view.vue'
import formularView from '@/views/formulars/formular-view.vue'

import reportView from '@/views/reports/report-view.vue'
import reportListView from '@/views/reports/report-list-view.vue'

import filesView from '@/views/main-views/files-view.vue'
import userView from '@/views/main-views/user-view.vue'

export default new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', redirect: { name: 'home' } },
        { path: '/login', name: 'login', component: loginView },
       
        {
            path: '/home', name: 'home', component: homeView, redirect: { name: 'report-list-view'},
            children: [
                { path: '/formular-list', name: 'formular-list-view', component: formularListView, children: [
                    { path: '/formular-list/formular/:id/:name', name: 'formular-view', component: formularView, props: true,  },
                    { path: '/no-formular', name: 'no-formular-view', template: '<p>kein Formular ausgewählt</P>' }

                    ] 
                },
                {
                    path: '/reports', name: 'report-view', component: reportView, redirect: { name: 'report-list-view' }, children: [
                    { path: '/reports/report-list-view', name: 'report-list-view', component: reportListView },
                    { path: '/reports/formular/:id/:name/:reportkey', name: 'report-details-view', component: formularView, props: true, },

                ] },

                { path: '/files', name: 'files-view', component: filesView },
                { path: '/user', name: 'user-view', component: userView },

            ]
        },

        { path: '*', template: '<p>404</P>' } // Not found
    ]
})