<template>
  <div id="app" class="vertical-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  computed: {
    token(){
      return this.$store.getters.token
    }
  },
  mounted(){
    this.$root.$moment.locale("de");
  },
  sockets: {
    'connect': function () {
      const token = localStorage.getItem('user-token')
      this.$socket.emit('check-token', { token })

      this.$store.commit('set-online', true)
    },
    'disconnect': function () {
      this.$store.commit('set-online', false)

    },
    'timeout': function () {
      this.$store.commit('set-online', false)

    },
    'login-success': function ({ token, userID, admin }){

      // if token is not set dont overide it
      if(token && token != '')
        this.$store.commit('set-token', token)
      
      this.$store.commit('set-user', {id: userID})
      this.$store.commit('set-admin', admin)


      if (this.$route.name == 'login')
        this.$router.push({ name: 'report-list-view' }).then().catch()

    },
    'login-failed': function(){
      this.$router.push({ name: 'login' })

    }
  },

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  width: 100vw;
}
.md-field input{
  width: 100%;
}
</style>
