<template>
    <div class="form-item drag-el" :class="{'edit-mode': editMode, 'edit': formItem.edit}" ref="form-item"
        :style="`flex-grow: ${(formItem.options && formItem.options.flexGrow)? formItem.options.flexGrow: 'auto'}; `"
        v-on:click.self="clickItem" :draggable="editMode" @dragstart.self="startDrag($event, formItem)">
        <div v-if="formItem.type == 'text'" class="vertical-wrapper" style="height: 100%" v-on:click="clickItem"
            @dragstart.self="startDrag($event, formItem)">
            <div class="placeholder"></div>
            <h1 v-if="!formItem.options.header || formItem.options.header == 'h1'"
                :style="`font-size: 2em;text-align: ${formItem.options.textAlign}`">{{formItem.name}}</h1>
            <h2 v-if="formItem.options.header == 'h2'" :style="`text-align: ${formItem.options.textAlign}`">
                {{formItem.name}}</h2>
            <h3 v-if="formItem.options.header == 'h3'" :style="`text-align: ${formItem.options.textAlign}`">
                {{formItem.name}}</h3>
            <h4 v-if="formItem.options.header == 'h4'" :style="`text-align: ${formItem.options.textAlign}`">
                {{formItem.name}}</h4>
            <h5 v-if="formItem.options.header == 'h5'" :style="`text-align: ${formItem.options.textAlign}`">
                {{formItem.name}}</h5>
            <div class="placeholder"></div>

        </div>
        <div v-if="formItem.type == 'panel'" class="panel"
            :class="{'horizontal-wrapper': !verticalWrapper, 'vertical-wrapper':verticalWrapper}"
            @dragstart.self="startDrag($event, formItem)">
            <div v-if="!formItem.items.length" class="horizontal-wrapper" style="height: 50pt">
                <div></div>
                <add-item v-on:move-item="key => $emit('move-item', key, formItem.items, 0)"></add-item>
                <div></div>
            </div>

            <div v-for="(fi, i) in formItem.items" :key="fi.key"
                :class="{'horizontal-wrapper': !verticalWrapper, 'vertical-wrapper':verticalWrapper}"
                :style="`flex-basis: ${i && editMode ? '0' : '35pt'}; flex-grow: ${(fi.options && fi.options.flexGrow) ? fi.options.flexGrow: 'auto'};`"
                @dragstart.self="startDrag($event, formItem)">
                <add-item v-if="!i" v-on:move-item="key => $emit('move-item', key, formItem.items, i)"
                    v-on:click="$emit('click-item', {})" />

                <form-item :form-item="fi" v-on:click-item="(e) => $emit('click-item', e)"
                    v-on:value-changed="e => $emit('value-changed', e)"
                    v-on:move-item="key => $emit('move-item', key, fi.items, i)"></form-item>

                <add-item v-on:move-item="key => $emit('move-item', key, formItem.items, i + 1)"
                    v-on:click="$emit('click-item', {})" />
            </div>

        </div>
        <div v-if="formItem.type == 'image'" class="icon horizontal-wrapper"
            :style="`background-image: url('${formItem.options.url}'); flex: 1 0 0; min-height: 75px; background-repeat: no-repeat;`"
            v-on:click="clickItem" @dragstart.self="startDrag($event, formItem)">
        </div>
        <div v-if="formItem.type == 'input'" class="horizontal-wrapper" v-on:click="clickItem"
            @dragstart.self="startDrag($event, formItem)">
            <div v-if="editMode && formItem.options && formItem.options.identifier" class="icon"
                :style="`background-image: url(${require('../assets/key-solid.svg')});  border-radius: 50%; background-color: var(--md-theme-default-primary); flex: 0 0 30pt;height: 30pt; border: 5pt solid  var(--md-theme-default-primary); margin: auto; margin-right: 10pt`">
            </div>

            <md-field :class="{ 'md-invalid': formItem.options && formItem.options.identifier && formItem.value == ''}">
                <label for="">{{formItem.name}}</label>
                <md-input v-model="formItem.value" style="width: 100%;" :disabled="editMode || reportClosed" @input="valueChanged" >
                </md-input>
            </md-field>
        </div>
        <div v-if="formItem.type == 'textarea'" v-on:click="clickItem" @dragstart.self="startDrag($event, formItem)">
            <md-field v-if="!pdfGenerating">
                <label for="">{{formItem.name}}</label>
                <md-textarea v-model="formItem.value" style="width: 100%; max-height: unset;" :disabled="editMode || reportClosed" @input="valueChanged"> 
                </md-textarea>
            </md-field>
            <div v-else style="text-align: left; border: solid 1pt rgba(0,0,0,0.54); border-radius: 2px; padding: 5px;">
                <p for="" style="margin: 0; text-align: left; color: rgba(0,0,0,0.54);">{{formItem.name}}</p>
                <div style="text-align: left; ">

                    {{ formItem.value }}
                </div>
            </div>
        </div>
        <div v-if="formItem.type == 'combobox'" ref="signatur-pad" v-on:click="clickItem"
            @dragstart.self="startDrag($event, formItem)">
            <md-field>
                <label for="">{{formItem.name}}</label>
                <md-select v-model="formItem.value" :disabled="editMode || reportClosed" v-on:md-selected="valueChanged">
                    <md-option v-for="item in comboFieldsItems" :key="item.key" :value="item.id">{{item.name}}
                    </md-option>
                </md-select>
            </md-field>
        </div>
        <div v-if="formItem.type == 'checkbox'" class="horizontal-wrapper" v-on:click="clickItem" @dragstart.self="startDrag($event, formItem)">
            <div :style="formItem.options.textAlign == 'left' ? `flex: 0 0 0;`: `flex: 1 0 0;`"></div>
            <md-switch class="md-primary" style="flex: 0 0 auto" :disabled="editMode || reportClosed" v-model="checkBoxValue" v-on:change="valueChanged">{{formItem.name}}</md-switch>
            <div :style="formItem.options.textAlign == 'right' ? `flex: 0 0 0;`: `flex: 1 0 0;`"></div>

        </div>

        <div v-if="formItem.type == 'table'" v-on:click="clickItem" @dragstart.self="startDrag($event, formItem)"
            ref="table-parent">
            <div ref="table"></div>
            <div class="horizontal-wrapper">
                <div></div>
                <md-button class="md-primary" :disabled="editMode || reportClosed" v-on:click="() => { table? table.insertRow(['', '']): 0; valueChanged()}">add
                </md-button>
                <div></div>
                <md-button v-if="defaultValuesChanged" v-on:click="saveDefaultTable" class="md-icon-button">
                    <md-icon :md-src="require('../assets/floppy-disk-solid.svg')"> </md-icon>
                </md-button>
                <input class="hide" ref="table-date-picker-input"/>
                <datetime style="flex: 0 0 0; text-align: center" class="hide-input" ref="table-date-picker" :title="tableDatePicker.title" :type="'date'" :value="tableDatePickerValue" v-on:input="tableDateChanged" >
                </datetime>

            </div>
        </div>

        <div v-if="formItem.type == 'signature'" ref="signatur-pad" v-on:click="clickItem"
            @dragstart.self="startDrag($event, formItem)">
            <signature-pad-popup :title="formItem.name" :sign.sync="formItem.value" @changed="valueChanged">

            </signature-pad-popup>

            <h3>{{formItem.name}}</h3>
        </div>
        <div v-if="formItem.type == 'datetime'" class="horizontal-wrapper" v-on:click="clickItem">
            <div :style="formItem.options.textAlign == 'left' ? `flex: 0 0 0;`: `flex: 1 0 0;`"></div>
            <p>{{ formItem.name }}</p>
            <div style="flex: 0 0 auto; margin-left: 5pt" class="vertical-wrapper">
                <div></div>
                <datetime :title="formItem.name" :type="formItem.options.format" v-model="datetimeValue" style="text-align: center" ></datetime>
                <div></div>
            </div>
            <div :style="formItem.options.textAlign == 'right' ? `flex: 0 0 0;`: `flex: 1 0 0;`"></div>

        </div>

    </div>
</template>
<script>
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars
import addItem from './add-item.vue';
import signaturePadPopUpVue from './signature-pad-popup.vue';

export default {
    name: 'form-item',
    props: { 'form-item': Object},
    components: { addItem, 'signature-pad-popup': signaturePadPopUpVue },

    methods: {
        startDrag(e, item){
            e.dataTransfer.dropEffect = 'move'
            e.dataTransfer.effectAllowed = 'move'
            e.dataTransfer.setData('dataKey', item.key)
        },
        clickItem(){
            if(this.editMode)
                this.$emit('click-item', this.formItem)
        },
        redrawTable(){
            if (this.formItem.type == 'table') {
                this.$nextTick(() => {
                    
                    if (this.formItem.value && typeof this.formItem.value == 'string')
                        this.formItem.value = JSON.parse(this.formItem.value)
                    const columns = typeof this.formItem.options.columns == 'string' ? JSON.parse(this.formItem.options.columns) : this.formItem.options.columns;
                    const tableWidth = this.$refs['table-parent'].clientWidth - 60;
                    const sumWidth = columns.reduce((arr, e) => arr + parseInt(e.flexWidth ? e.flexWidth : 1), 0);
                    if (tableWidth < 10)
                        return

                    if(columns.reduce((arr, e) => arr || e.order == undefined , false))
                        columns.forEach((e, i) => e.order = i)

                    columns.forEach(e => {
                        e.width = parseInt(e.flexWidth ? e.flexWidth : 1) * (tableWidth / sumWidth)

                        if (e.IDh_combofield && e.IDh_combofield > 0){
                            const m = this.comboFields.find(cF => cF.id == e.IDh_combofield)
                            if(m){
                                e.source = m.items.map(e => e.name)
                            }
                        }

                        if (e.type == 'date'){
                            e.editor = {
                                openEditor: this.openDateTimePicker,
                                closeEditor: (cell, value) => {
                                    console.log('closeEditor', cell.innerHTML, value);
                                    return cell.innerHTML
                                },
                                getValue: function (cell) {
                                    return cell.innerHTML
                                },
                                setValue: function (cell, value) {
                                    cell.innerHTML = `${value}`;
                                }
                            }
                        }
                    })
                    this.$refs['table'].innerHTML = ''

                    let data;
                    if (this.formItem.value)
                        data = this.formItem.value;
                    else if (this.formItem.options.defaultValues)
                        data = typeof this.formItem.options.defaultValues == 'string' ? JSON.parse(this.formItem.options.defaultValues) : this.formItem.options.defaultValues;
                    else 
                        data = [['', '']];

                    this.table = jexcel(this.$refs['table'], {
                        data: data,
                        columns: columns,
                        onafterchanges: this.onafterchanges,
                        onresizecolumn: this.onresizecolumn,
                        onbeforeinsertcolumn: () => {return false},
                    })

                })
            }
        },

        valueChanged(){
            this.$emit('value-changed', this.formItem)
        },
        onafterchanges(){
            //alert('changed')
            this.formItem.value = this.table.getData()
            
            this.$emit('value-changed', this.formItem)

            if (!this.isInReport){
                this.defaultValuesChanged = true
            }
        },
        onresizecolumn(){
            ///console.log('onresizecolumn');
        },
        saveDefaultTable(){
            this.formItem.saveValues = true;
            this.$emit('value-changed', this.formItem)

        },

        openDateTimePicker(html){
            if (this.reportClosed)
                return
            
            const x = parseInt(html.getAttribute('data-x'))
            const y = parseInt(html.getAttribute('data-y'))
            
            const value = this.table.getValueFromCoords(x, y)
            this.tableDatePicker.coords =  [x, y]
            if(value == '')
                this.tableDatePickerValue = this.$moment().format('YYYY-MM-DDTHH:mm:ssZ')
            else
                this.tableDatePickerValue = this.$moment(value + '12:00').format('YYYY-MM-DDTHH:mm:ssZ')
            
            this.$refs['table-date-picker-input'].focus()
            this.$nextTick(() => {

                this.$refs['table-date-picker'].open({ target: { blur: () => { } } });

                this.table.closeEditor(html, true);
            }); 

        },
        tableDateChanged(e){
            this.$refs['table-date-picker-input'].blur()
            
            if (!this.table)
                return

            
            console.log('Save Date in Table');            
            const [x, y] = this.tableDatePicker.coords
            const table = this.table.getData()
            table[y][x] = this.$moment(e).format('YYYY-MM-DD')
            this.table.setData(table)


            this.onafterchanges()
           
        }
    },
    data(){
        this.$moment.locale("de");
        return {
            table: 0,
            defaultValuesChanged: false,


            tableDatePickerValue: this.$moment().format(),
            tableDatePicker: {
                open: false,
                title: 'Datum',
                format: 'date',
                coords: [0, 0]

            }


        }
    },
    mounted(){
        this.redrawTable()
        
        if (this.formItem.options && parseInt(this.formItem.options.identifier)){
            this.formItem.options.identifier = parseInt(this.formItem.options.identifier)? true :false
        }


        if(this.formItem.type == 'combobox'){
            this.formItem.options.items = (typeof this.formItem.options.items === 'string') ? JSON.parse(this.formItem.options.items) : this.formItem.options.items;
            if (this.formItem.options.IDh_combofield > 0 && this.formItem.options.dropdownType == 'comboField' && this.comboFields.some(e => e.id == this.formItem.options.IDh_combofield)){
                this.formItem.options.items = this.comboFields.find(e => e.id == this.formItem.options.IDh_combofield).items
            }
        }
        else if(this.formItem.type == 'checkbox'){
           // this.formItem.value = parseInt(this.formItem.value)? true: false;
        }
        
        if (this.formItem.options.autoFill && this.formItem.value == ''){
            const e = this.formItem.options.autoFill;
            this.formItem.options.autoFill = e;
            if(e.type == 'js' && e.code){
                try{
                    var result = new Function(e.code)();
                    this.formItem.value = result
                }catch(e){
                    console.log('Error In Autofill');
                    console.log(e)
                }
            }
            //console.log('fill with js',e.code);
        }
    },
    watch:{
        formItem: {
            handler(val) {
                if (val.redraw){
                    this.redrawTable()
                    val.redraw = false
                }
            },
            deep: true
        }
        
    },
    computed:{
        editMode(){
            return this.$store.getters.editMode
        },
        verticalWrapper(){
            return this.formItem && 
            this.formItem.type == 'panel' && 
            this.formItem.options && 
            this.formItem.options.orientation && 
            this.formItem.options.orientation == 'vertical'
        },
        isInReport(){
            return this.$route.name == 'report-details-view';
        },
        reportClosed(){
            return ['closed', 'for billing', 'billed'].includes(e => e == this.$store.getters.reportStatus);
        },
        comboFields(){
            return this.$store.getters.getComboFields
        },

        comboFieldsItems(){
            if (this.formItem.type != 'combobox')
                return []

            let ITEMS = (typeof this.formItem.options.items === 'string') ? JSON.parse(this.formItem.options.items) : this.formItem.options.items;
            if(this.formItem.options.dropdownType == 'comboField'){
                const m = this.$store.getters.getComboFields.find(e => e.id == this.formItem.options.IDh_combofield)
                ITEMS = m? m.items: ITEMS
            }
            return ITEMS
        },
        columnComboFieldItems(){
            let ITEMS = []
            const m = this.$store.getters.getComboFields.find(e => e.id == this.formItem.options.IDh_combofield)
            ITEMS = m ? m.items : ITEMS
            return ITEMS
        },
        datetimeValue: {
            get(){
                if (this.formItem.value)
                    return this.$moment(this.formItem.value).format()
                else    
                    return this.$moment().format()
            }, set(v){
                this.formItem.value = v
                this.valueChanged()
            }
        },
        pdfGenerating(){
            return this.$store.getters.getPdfGenerating
        },
        
        checkBoxValue:{
            set(v){
                this.formItem.value = v? '1': '0'
            },
            get(){

                return this.formItem.value == '1'
            }
        }
    }
}
</script>
<style>
.form-content > .form-item{
    flex: 0 0 auto;
}
div.form-item{
    margin: 2pt;
    flex: 1 0 0;
}
div.form-item.edit-mode{
    border: dashed 1pt darkgray;
    margin: 5pt 2pt;
}
div.form-item.edit-mode.edit{
    border-color: darkblue;
}
div.form-item.edit-mode .panel {
    margin: 10pt auto;
}

.vdatetime.hide-input input{
    display: none;
}
</style>