<template>
    <div v-if="editMode" class="add-item icon drag-zone"
        :style="(hoverOver)? `background-image:url(${require('../assets/anchor-solid.svg')})` : ''" 
        v-on:click="$emit('click')"
        @drop="onDrop" 
        @dragover.prevent
        
        @dragenter="dragEnter"
        @dragleave="dragLeave"
        >

    </div>
</template>
<script>
export default {
    name: 'add-item',
    methods:{
        onDrop(e) {
            this.hoverOver = false
            console.log('move-item', e.dataTransfer.getData('dataKey'));
            this.$emit('move-item', e.dataTransfer.getData('dataKey'));

        },
        dragEnter(e){
            e.preventDefault()

            this.hoverOver = true
        },
        dragLeave(e){
            e.preventDefault()
            this.hoverOver = false
        }
    },
    computed:{
        editMode(){
            return this.$store.getters.editMode
        }
    },
    data(){
        return{
            hoverOver: false
        }
    }

}
</script>
<style>


div.add-item{
    flex: 0 0 35pt !important;
    
    margin: 2pt;
    border: solid lightblue 7.5pt;
    /*border: solid darkgray 2pt;*/
    background-color: lightblue;
    cursor: pointer
}
</style>