<template>
    <div>
        <md-button class="md-primary md-raised" v-on:click="open">Editor</md-button>
        <md-dialog :md-active="show">
            <div class="horizontal-wrapper">
                <md-dialog-title>Daten Sets</md-dialog-title>
                <md-dialog-actions>
                    <md-button class="md-primary" v-on:click="save">Save</md-button>
                    <md-button class="md-primary" @click="show = false">Close</md-button>
                </md-dialog-actions>
            </div>
            <div class="vertical-wrapper" style="min-width: 70vw; min-height: 70vh; margin: 20pt">
                <div style="flex: 0 0 auto">
                    <md-field>
                        <label for=""></label>
                        <md-select  v-model="selectedComboFieldKey" v-on:md-selected="e => e =='new'? addComboField(): selectComboField(e)">
                            <md-option v-for="item in comboFields " :key="item.key" :value="item.key">{{ item.name }}</md-option>
                            <md-option value="new">+</md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="vertical-wrapper">
             
                    <md-field style="flex: 0 0 auto"> 
                        <label for="">Name</label>
                        <md-input v-model="selectedComboField.name"></md-input>
                    </md-field>
                    <div style="max-height: 400pt; overflow-y: scroll">

                        <draggable v-model="selectedComboField.items" handle=".bars"  @end="reOrder">
                            <div v-for="(item) of selectedComboField.items" :key="item.key" class="horizontal-wrapper">
                                <div class="icon bars"
                                    :style="`flex: 0 0 15pt; cursor:pointer; margin: 10pt; background-image: url(${require('../assets/bars-solid.svg')});`">
                                </div>
                        
                                <md-field style="margin-right: 5pt">
                                    <label for="">name</label>
                                    <md-input v-model="item.name" ></md-input>
                                </md-field>
                                <md-field style="margin-left: 5pt">
                                    <label for="">value</label>
                                    <md-input v-model="item.value"></md-input>
                                </md-field>
                                <div class="icon" v-on:click="deleteItem(item)"
                                    :style="`flex: 0 0 15pt; cursor:pointer; margin: 10pt; background-image: url(${require('../assets/trash-can-solid.svg')});`">
                                </div>
                            </div>
                        </draggable>

                        <div class="horizontal-wrapper">
                            <div></div>
                            <md-button class="md-raised md-primary" v-on:click="addItem">add</md-button>
                            <div></div>

                        </div>
                    </div>

                </div>
            </div>
        </md-dialog>
    </div>
</template>
<script>
import draggable from 'vuedraggable'

export default{
    name: 'data-set-editor-dialog',
    components:{
        draggable,

    },
    methods:{
        open(){
            this.show = true;
            //
            this.$nextTick(() => {
                this.selectedComboFieldKey = this.comboFields.length? this.comboFields[0].key: 0;
                this.$forceUpdate()
            })
        },
        selectComboField(e){
            this.selectedComboFieldKey = e
        },
        async addComboField(){
            const { isConfirmed } = await this.$swal.fire({
                title: 'Neues Combo Feld hinzufügen?',
                icon: 'question',

                showConfirmButton: true,
                confirmButtonText: 'Ja',

                showDenyButton: true,
                denyButtonText: 'Nein',
            })
            if(!isConfirmed)
                return
            const comboFields = this.comboFields;
            const key = this.$uuid.v4()
            comboFields.push({id: -1, name: 'neues Combo Feld', items: [], key: key})
            //this.$store.commit('set-combo-field', comboFields)
            this.$nextTick(() => {
                this.selectedComboFieldKey = key
                this.$forceUpdate()
            })
        },
        addItem() {
            if (!this.selectedComboField.items)
                return
            this.selectedComboField.items.push({ id: -1, key: this.$uuid.v4(), name: '', value: '', order_number: this.selectedComboField.items.length})
        },
        save(){
            this.$socket.emit('edit-combo-fields', {token : this.token, comboFields: this.comboFields})
            this.show = false
        },
        deleteItem(item){
            this.selectComboField.items = this.selectComboField.items.filter(e => e.key != item.key) 
           // this.$store.commit('set-combo-field', )
            
        }, 
        reOrder(){
            console.log(this.selectedComboField.items.map(e => e.name));


        }   
    },
    computed:{
        token(){
            return this.$store.getters.token;
        },
        comboFields: {
            get() {
                return this.$store.getters.getComboFields
            },
            set(v){
                //console.log(v);
                this.$store.commit('set-combo-field', v)
            }
        },
        selectedComboField(){
            const cF = this.comboFields.find(e => this.selectedComboFieldKey == e.key)

            return cF? cF: {}
        },
    },
    data(){
        return{
            show: false,
            selectedComboFieldKey: 0,
            list: [{name:'hello', key: this.$uuid.v4()}]
        }
    }
}
</script>