<template>
    <div>
        <h1>Login</h1>
        <div class="vertical-wrapper" style="margin: 3.5%">

            <md-field>
                <label for="">Mandant</label>
                <md-select v-model="selectedMandate">
                    <md-option v-for="m in mandates" :key="m.key" :value="m.id">{{m.name}}</md-option>
                </md-select>
            </md-field>

            <md-field>
                <label for="">Username</label>
                <md-input v-model="username" v-on:keyup.enter="login"></md-input>
            </md-field>
            <md-field>
                <label for="">Password</label>
                <md-input type="password" v-model="pw" v-on:keyup.enter="login"></md-input>
            </md-field>

            <md-button class="md-primary md-raised" style="margin: 0" v-on:click="login">Login</md-button>
        </div>


    </div>
</template>
<script>
export default {
    name: 'login-view',
    methods:{
        login(){
            this.loginAtempt = true;
            this.$socket.emit('login', { mandate: { id: this.selectedMandate }, name: this.username, password: this.pw })
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$socket.emit('get-mandates', {  })
            console.log('get-mandates')
            next();
        })
    },
    sockets:{
        'set-mandates':function({mandates}){
            mandates.forEach(e => e.key = this.$uuid.v4())
            this.mandates = mandates;
            
            if(mandates.length)
                this.selectedMandate = mandates[0].id
        },
        'login-failed': function () {
            if(!this.loginAtempt)
                return

            this.$swal({
                icon: 'error',
                title: 'Login failed',
                text: 'wrong login creditals',
            })
        },
    },
    data(){
        return{
            mandates: [],
            selectedMandate: 0,
            
            loginAtempt: false,
            username: '', 
            pw: ''
        }
    }
}
</script>