<template>
    <div>
        <div class="opener signature-pad" v-on:click="openSignaturePad" >
            <!--<img v-bind:src="this.svg" height="200px" width="auto" />-->
            <canvas ref="sign-canvas" height="300px" width="600px"></canvas>
        </div>
        <md-dialog :md-active.sync="open">
            <md-dialog-title>{{title}}</md-dialog-title>

            <div class="content vertical-wrapper">
                <span>{{resolution}}</span>
                <VueSignaturePad class="" width="600px" height="300px" ref="signaturePad" />

            </div>
            <md-dialog-actions>
                <md-button class="md-primary" @click="open = false">Close</md-button>
                <md-button class="md-primary" @click="saveSignature()">Save</md-button>
            </md-dialog-actions>

        </md-dialog>
    </div>
</template>
<script>
export default {
    name: 'signature-pad-popup',
    props: { 'title':String, 'sign':String },
    methods: {
        openSignaturePad(){
            if(this.editMode || this.reportClosed)
                return
            this.open = true
            this.$nextTick(() => {
                this.$refs.signaturePad.clearSignature()
            });

        },
        saveSignature(){
            this.svg = this.$refs['signaturePad'].saveSignature('image/png').data
            this.$emit('save-sign', this.svg)
            //this.$refs['opener-canvas'].getContext('2d').drawImage(new Image(this.svg), 0 , 0)
            this.open = false

            const canvas = this.$refs['sign-canvas']
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            //alert(canvas.width + 'x' + canvas.height)
            const image = new Image();
            image.onload = function () {
                ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            };

            image.src = this.svg
            this.$emit('update:sign', this.svg)
            this.$emit('changed')
            //console.log(this.svg);

        }
    },
    mounted(){
        this.$nextTick(() => {
            if (this.sign && this.sign != ''){
                const canvas = this.$refs['sign-canvas']
                const ctx = canvas.getContext("2d");
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                const image = new Image();
                image.onload = function () {
                    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
                };

                image.src = this.sign
            }
        })
    },
    data(){
        return {
            open: false,
            svg: '',
        }
    }, 
    computed: {
        editMode() {
            return this.$store.getters.editMode
        },
        reportClosed() {
            return this.$store.getters.reportStatus == 'closed';
        },
        resolution(){
            const canvas = this.$refs['sign-canvas']
            if (!canvas)
                return 'x'
            const x = canvas.width
            const y = canvas.height

            return `${x}x${y}`
        }

    }
}
</script>
<style>

.signature-pad {
    border: solid 1pt darkgray;
}
</style>