<template>
    <div style="" class="horizontal-wrapper" ref="formular-wrapper"
        :style="`background-color: rgba(0, 0, 0, 0.8);${(isInReport)? `overflow-y: hidden;`: ''}`">
        <editor 
            :edit-object="editObject" 
            v-on:add-item="addItem" 
            v-on:delete-item="deleteItem"
            v-on:delete-formular="deleteFormular"
            v-on:copy-formular="copyFormular" 
            :formular="formularData" 
            style="flex: 1 0 0"  />

        <div class="vertical-wrapper" style="flex: 4 0 0" v-on:click.self="clickItem({})">
            <md-toolbar v-if="isInReport" class="md-primary horizontal-wrapper report-toolbar" style="flex: 0 0 auto">
                <md-button v-on:click="$router.push({name: 'report-list-view'}) " class="md-icon-button">
                    <md-icon :md-src="require('../../assets/arrow-left-solid.svg')"> </md-icon>
                </md-button>
                <h3 class="md-title" style="flex: 1">Report</h3>
                <md-field class="md-inverted md-in-toolbar " id="report-selector">
                    <label for="">Status</label>
                    <md-select v-model="reportStatus">
                        <md-option v-for="rs in reportStats" :key="rs.key" :value="rs.value"
                            class="md-inverted md-in-toolbar"
                            style="background-color: var(--md-theme-default-primary); color: white">{{rs.name}}
                        </md-option>
                    </md-select>
                </md-field>
                <div></div>
                <md-button v-if="isAdmin" v-on:click="repairReport" class="hide md-icon-button">
                    <md-icon :md-src="require('../../assets/screwdriver-wrench-solid.svg')"> </md-icon>
                    <input type="file" ref="file-input" style="display: none" />
                </md-button>
                <md-button v-on:click="downloadReport" class="md-icon-button">
                    <md-icon :md-src="require('../../assets/file-arrow-down-solid.svg')"> </md-icon>
                </md-button>

                <md-button v-if="valueHasChanged" v-on:click="saveReport" class="md-icon-button">
                    <md-icon :md-src="require('../../assets/floppy-disk-solid.svg')"> </md-icon>
                </md-button>
                <md-button v-if="reportStatus == 'open'" v-on:click="closeReport(0)" class="md-icon-button">
                    <md-icon :md-src="require('../../assets/file-shield-solid.svg')"> </md-icon>
                </md-button>
                <md-button v-if="reportStatus == 'for billing'" v-on:click="closeReport(1)" class="md-icon-button">
                    <md-icon :md-src="require('../../assets/cash-register-solid.svg')"> </md-icon>
                </md-button>


                <md-button v-on:click="sendReport" class="md-icon-button">
                    <md-icon :md-src="require('../../assets/paper-plane-solid.svg')"> </md-icon>
                </md-button>
                <md-button  v-on:click="exitReport" class="md-icon-button">
                    <md-icon :md-src="require('../../assets/right-from-bracket-solid.svg')"></md-icon>
                </md-button>
                <md-button v-on:click="deleteReport" class="md-icon-button">
                    <md-icon :md-src="require('../../assets/trash-can-solid.svg')"> </md-icon>
                </md-button>

            </md-toolbar>

            <div class="vertical-wrapper" style="flex: 1 0 auto;  " v-on:click.self="clickItem({})"
                :style="`max-height: calc(100vh - ${footerHeight}px); overflow-y:scroll; overflow-x: auto;`">



                <div class="form-content vertical-wrapper" v-on:click.self="clickItem({})"
                    :style="`background-color: white; flex: 1 0 ${297 * scale}px;  margin: 20pt auto; padding: 2pt;`">

                    <add-item v-if="!items || !items.length" v-on:move-item="key => moveItem(key, 0, 0)">
                    </add-item>

                    <vue-html2pdf class="fomular-view vertical-wrapper" 
                        :show-layout="true" 
                        :pdfBlobUrl="true"
                        :enable-download="manualDownload" 
                        :preview-modal="false" 
                        :filename="filename"
                        :paginate-elements-by-height="1100" :pdf-quality="2" @beforeDownload="beforeDownload"
                        @hasDownloaded="hasDownloaded" pdf-format="a4" pdf-orientation="portrait"
                        :pdf-content-width="`795px`" :manual-pagination="false" ref="html2Pdf"
                        @click.self="clickItem({})">
                        <section slot="pdf-content" v-on:click.self="clickItem({})">
                            <div v-for="(fi, i) in items" :key="fi.key" v-on:click.self="clickItem({})">
                                <!--<div v-if="fi.reachEndOfPaper" class="html2pdf__page-break"></div>-->
                                <section class="pdf-item" v-on:click.self="clickItem({})">
                                    <div class="vertical-wrapper" style="flex: 0 0 auto" ref="formItems"
                                        v-on:click.self="clickItem({})">
                                        <add-item v-if="!i" v-on:move-item="key => moveItem(key, 0, 0)"></add-item>

                                        <form-item :form-item="fi" v-on:click-item="clickItem" v-on:move-item="moveItem"
                                            v-on:value-changed="valueChanged" ref="item"></form-item>

                                        <add-item v-on:move-item="key => moveItem(key, 0, i+1)"
                                            v-on:click="clickItem({})">
                                        </add-item>
                                    </div>

                                </section>
                            </div>
                        </section>
                    </vue-html2pdf>
                    <div class="clickable"  v-if="!isInReport" v-on:click="clickItem({})" style="flex: 1 0 0; background-color: white;">
                    </div>
                </div>


            </div>
        </div>

    </div>

</template>
<script>
import addItemVue from '../../components/add-item.vue';
import Editor from '../../components/editor.vue';
import formItemVue from '../../components/form-item.vue'
import _ from 'lodash';
export default {
    name: 'formular-view',
    props: ['id', 'name', 'reportkey'],
    components: { 'form-item':formItemVue, 'add-item':addItemVue, Editor},
    methods: {
        clickItem(item){
            const deselectAll = function(item){
                item.edit = false;
                if(item.type == 'panel')
                    item.items.forEach(e => deselectAll(e))
            }
            this.items.forEach(e => deselectAll(e))
            if(item.edit !== undefined)
                item.edit = true
            this.editObject = item
        },
        popItem(key, parentItems){
            let i = 0;

            for (const e of parentItems){
                if(e.key == key){
                    parentItems.splice(i, 1)
                    return e
                }
                if(e.type == 'panel'){
                    const res = this.popItem(key, e.items)
                    if(res)
                        return res
                }
                i++;
            }
        },
        moveItem(key, parentItems, position){
            if (!parentItems)
                parentItems = this.items
            const item = (key.includes('new-key/')) ? this.addItem(key.split('/')[1], parentItems, position) : this.popItem(key, this.items)

            parentItems.forEach((e, i) => e.position = i + (e.position >= position))
            item.position = position
            if (!key.includes('new-key/'))
                parentItems.push(item)

            parentItems.sort((a, b) => a.position - b.position)
        },
        addItem(type, parentItems, position){
            const item = { id: -1, type: type, name: '', key: this.$uuid.v4(), edit: false, position: (position == undefined)? this.items.length: position, options: {flexGrow: 1}}
            if(type == 'panel')
                item.items = []
            else if(type == 'text'){
                item.options.textAlign = 'center'
                item.options.header = 'h1'
                item.options.identifier = 0
                item.options.searchAttribute = 0
            }
            else if(type == 'image')
                item.options['url'] = ''
            else if(type == 'table')
                item.options.columns =  [{ title: 'col1', width: 1 }, { title: 'col 2', width: 1 }]
            else if(type == 'combobox'){
                item.options.items = [{ id: this.$uuid.v4(), name: 'Option 1' }, { id: this.$uuid.v4(), name: 'Option 2' }]
                item.options.IDh_combofield = -1
            }
            else if(type == 'input'){
                item.options.identifier = false
                item.options.autoFill = false
            }else if(type == 'DateTime')
                item.options = { format: 'datetime', textAlign:'center' }

            if (parentItems)
                parentItems.push(item)
            else
                this.items.push(item)

            return item
        },
        deleteItem(item){
            this.popItem(item.key, this.items)
        },
        mapItems(items, cb){
            if (!items)
                return
            items.forEach(e => {
                cb(e)
                if(e.type == 'panel'){
                    this.mapItems(e.items, cb)
                }
            })
            
        },
        valueChanged(item){
            this.valueHasChanged = true
            if ((!this.isInReport) && item.saveValues && item.type == 'table'){
                item.saveValues = false
                item.options.defaultValues = item.value
                this.$socket.emit('edit-formular-details', { token: this.token, formular: { id: this.id, name: this.formularData.name, items: this.items } })

            }
        },
        copyFormular(f){
            const formularCopyItems = JSON.parse(JSON.stringify(this.items))
            this.mapItems(formularCopyItems, (e) => { 
                e.id = -1
                e.parent_id = e.parent_id? -1: e.parent_id
            })
            this.$socket.emit('edit-formular-details', { token: this.token, formular: { id: -1, name: f.name, items: formularCopyItems }, isCopy: true})
        },
        deleteFormular(){
            this.$socket.emit('delete-formular', {token: this.token, formular: {id: this.id, name: this.name}})
            this.$router.push({ name: 'formular-list-view'})
        },

        /*Reports*/
        saveReport(){
            console.log('Save Report', !this.doNotSave);
            if(!this.doNotSave){
                let reportId = this.reportid;
                if(this.internalReportId > 0)
                    reportId = this.internalReportId;
                let reportKey = this.reportKey;
                if((!this.reportKey) || this.reportKey == '-1')
                    reportKey = this.internalReportKey

                let keyIdetifier = ''
                let author = ''
                const searchAttributes = []

                this.mapItems(this.items, (e) => {
                    if (e.options.identifier){
                        keyIdetifier = e.value
                        console.log(e.options);
                    }
                    if (e.options.searchAttribute)
                        searchAttributes.push(e.value)
                    if (e.options.authorField){
                        if(e.type == 'combobox'){
                            const m = e.options.items.find(i => e.value == i.value)
                            author = m ? m.name : ''
                        }else
                            author = e.value
                    }
                })

                if(!this.valueHasChanged)
                    return

                if(reportKey < 0 || reportKey == '-1'){
                    reportKey = this.$uuid.v4()
                }
                this.$store.commit('save-report', { 
                    formular: {id: this.id, name: this.name},  
                    report: { 
                        key: reportKey, 
                        id: reportId, 
                        keyIdetifier: keyIdetifier, 
                        notSynced: true, 
                        state: this.reportStatus,
                        searchAttributes: searchAttributes ,
                        author
                    }, 
                        
                    items: this.items, 
                    newReportKey: reportKey
                    } 
                    )
                this.internalReportKey = reportKey
                
                
                fetch(`${this.$hostname}/files/save`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        token: this.token,
                        report: {
                            formular: { id: this.id, name: this.name },
                            key: reportKey,
                            id: reportId,
                            items: this.items,
                            state: this.reportStatus,
                            searchAttributes: searchAttributes
                        }
                    })
                }).then(e => {
                    if(e.status != 200){
                        alert('Error'+ e.status);
                    }
                }).catch( () => {})

               // this.$store.dispatch('sync-reports')

                //if(this.online)
                    //this.$socket.emit('edit-report-details', { token: this.token, report: { id: reportId, items: this.items }, formular: { id: this.id, name: this.name },  })
            }
        },
        downloadReport(){
            let keyIndetifier = ''
            this.mapItems(this.items, (e) => {
                if(e.options.identifier)
                    keyIndetifier = keyIndetifier + e.value
            })
            this.filename = keyIndetifier + '_' + this.name
            if (this.$refs['formItems'] && this.$refs['formItems'].length){
                this.$refs['formItems'].reduce((top, e, i) => {
                    const reachedHeight = top + e.clientHeight
                    console.log(e);
                    if(reachedHeight < 1100)
                        return reachedHeight
                    else{
                        this.items[i].reachEndOfPaper = true
                        return 0
                    }
                    
                }, 0)
            }
            this.manualDownload = true

            this.$store.commit('set-pdf-generating', true)
            this.$nextTick(() => {
                this.$refs['html2Pdf'].generatePdf()
                
            })
        },
        closeReport( type ){
            let status = 'for billing'
            let message = {
                icon: 'question',
                title: 'Report zur Abbrechnung?',
                text: 'Report kann nicht mehr bearbeitet werden',

                showDenyButton: true,
                showConfirmButton: true,

                denyButtonText: 'Nein',
                confirmButtonText: 'Ja',

            }
            if (type == 1){
                message = {
                    icon: 'question',
                    title: 'Report Abbgerechnet?',
                    text: 'Report kann nicht mehr bearbeitet werden',

                    showDenyButton: true,
                    showConfirmButton: true,

                    denyButtonText: 'Nein',
                    confirmButtonText: 'Ja',
                }
                status = 'billed'
            }

            this.$swal(message).then(e => {
                if (e.isConfirmed) {
                    this.$socket.emit('edit-report-status', { token: this.token, report: { id: this.reportid, items: this.items, status: status } })
                    this.manualDownload = false
                    this.$refs['html2Pdf'].generatePdf()
                        

                }
            })
           
        },
        sendReport(){
            this.$swal({
                icon: 'question',
                title: 'Email Report?',

                input: 'email',
                inputPlaceholder: '',
                
                showDenyButton: true,
                showConfirmButton: true,

                denyButtonText: 'Nein',
                confirmButtonText: 'Ja',


            }).then(e => {
                if(e.isConfirmed){
                    this.reportToMail = e.value;
                    this.startSendReport = true;


                    this.$store.commit('set-pdf-generating', true)
                    this.$nextTick(() => {
                        this.$refs['html2Pdf'].generatePdf()
                    })
                }
            })
        },
        async exitReport(){
            const { isConfirmed, isDenied } = await this.$swal.fire({
                icon: 'question',
                title: 'Verlassen?',
                text: 'Report ohne speichern verlassen?',

                showConfirmButton: true,
                confirmButtonText: 'Ja',

                showDenyButton: true,
                denyButtonText: 'Speichern',

                showCancelButton: true,
                cancelButtonText: 'Abbrechen'
            })
            if (isConfirmed) {
                this.doNotSave = true
                this.$router.go(-1)
            } else if (isDenied){
                this.saveReport()
                this.$router.go(-1)

            }
        },
        deleteReport(){
            this.$swal({
                icon: 'warning',
                title: 'Delete Report?',
                text: 'Are you sure to delete Report?',


                showDenyButton: true,
                showConfirmButton: true,

                denyButtonText: 'Nein',
                confirmButtonText: 'Ja',


            }).then(e => {
                if (e.isConfirmed) {
                    this.$socket.emit('delete-report', {token: this.token, report: {id: this.reportid}})
                    this.$router.push({ name: 'report-list-view'})
                }
            })
        },

        /*VUE Generate PDF*/
        beforeDownload({html2pdf, options, pdfContent}){
            if (this.manualDownload)
                return
            let keyIndetifier = ''
            this.mapItems(this.items, (e) => {
                if (e.options.identifier)
                    keyIndetifier = e.value
            })

            if(this.startSendReport){
                html2pdf().set(options).from(pdfContent).outputPdf().then((pdf) => {
                    this.$store.commit('set-pdf-generating', false)

                    console.log('SEND REPORT HERE');
                    this.startSendReport = false
                    this.$socket.emit('send-email-report', { token: this.token, email: this.reportToMail, blob: btoa(pdf) , filename: keyIndetifier + '_' + this.name})
                })
                return
            }

            html2pdf().set(options).from(pdfContent).outputPdf().then((pdf) => {
                this.$socket.emit('save-report-file', { token: this.token, report: { id: this.reportid, name: keyIndetifier + '_' + this.name }, file: btoa(pdf) })
            })
        },
        hasDownloaded(){
            this.manualDownload = false
            this.$store.commit('set-pdf-generating', false)

            
        },
        reloadFormular() {
            //console.log('Reload Formular');
            this.items = this.$store.getters.formularItems(this.id)

        },
        repairReport(){
            this.$refs['file-input'].click()
            
        }
        
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        reportSaved(){
            return this.$store.getters.reportSaved
        },
        footerHeight(){
            return this.$store.getters.footerHeight
        }, 
        online() {
            return this.$store.getters.online
        },
        isInReport() {
            return this.$route.name == 'report-details-view';
        },
      
        reportid(){
            const key = this.reportkey;
            return this.$store.getters.reportIdByKey(key)
        },
        reportStatus:{
            get(){
                const key = this.reportkey;
                const r = this.$store.getters.reports.find(e => e.key == key)
                return r? r.status: 'open'
            },
            set(){
                
            }

        }, 
        isAdmin() {
            return this.$store.getters.isadmin
        },


    },
    watch:{
        id(){
            if(this.online)
                this.$socket.emit('get-formular-details', { token: this.token, formular: { id: this.id, name: this.name } })
            else{

                this.items = this.$store.getters.formularItems(this.id)
            }
        },
        name(){
            this.formularData.name = this.$props.name;
        },
      
    },
    sockets: {
     
     
        'set-report-id':function({reportId}){
            this.internalReportId = reportId;
            this.mapItems(this.items, (e) => e.IDh_report_item = reportId)

            console.log('set-report-id');
        },
        'set-combo-fields':function({comboFields}){
            comboFields.forEach(e => e.key = this.$uuid.v4())
            this.$store.commit('set-combo-field', comboFields)
        },
        'report-status-changed': function(){
            this.$swal.fire({ 
                title: 'Status geändert', 
                text: 'Berichtstatus erfolgreich geändert', 
                icon: 'success',
                showCancelButton: false,
                showConfirmButton: true,
                confirmButtonText: 'ok',
            }).then(() => {

                this.$router.push({ name: 'report-view' })
            })
        }
    },
    beforeRouteEnter(to, from, next) {

        next(vm => {
            console.log('[Router] before Route Enter')
            vm.reportViewLoaded = true;

            const openReport = !((!vm.reportkey) || vm.reportkey == '-1' || vm.reportid == '')
            vm.$store.commit('set-pdf-generating', false)

            vm.$socket.emit('get-combo-fields', {token: vm.token})

            console.log(openReport, vm.online)
            //console.log(openReport , vm.online);
            if(openReport && vm.online){
                //vm.$socket.emit('get-report-details', { token: vm.token, report: { id: vm.reportid, key: vm.reportkey }, formular: { id: vm.id, name: vm.name } })
                vm.internalReportKey = vm.reportkey

            }else if(!openReport && vm.online){
                console.log('get-formular-details')
                vm.$socket.emit('get-formular-details', { token: vm.token, formular: {id:vm.id, name:vm.name} })
            }

            if (!openReport){ //new report in offline mode
                //deepcopy to prevent data transactions
                vm.items = _.cloneDeep(vm.$store.getters.formularItems(vm.id))
                vm.internalReportKey = -1
                console.log('Open Empty Report');

            }else if (openReport){// exisiting report in offline mode

                // get items with tree structure from formulars
                vm.items = _.cloneDeep(vm.$store.getters.formularItems(vm.id))

                // get report items form store
            
                const reportsItems = _.cloneDeep(vm.$store.getters.reportItemsbyKey(vm.reportkey))

                // map through tree and assign values
                vm.$mapItems(vm.items, formItem => {
                    const m = reportsItems.find(e => e.IDh_form_item == formItem.id)
                    if(m){
                        // set value
                        // transform if needed
                        formItem.value = m.value;
                        console.log(m.value, m.IDh_form_item);


                        formItem.IDh_report_item = m.id;
                      
                        //override options
                        Object.keys(m.options).forEach(okey => {
                            formItem.options[okey] = m.options[okey] 
                        })
                        
                    }
                })
                console.log('Override Values');

                vm.internalReportKey = vm.reportkey
            }
            vm.doNotSave = false;
            next();
        })
    },
    beforeRouteLeave(to, from, next) {
        try{
       
            if (this.isInReport) {
                this.saveReport()
            }
            next()
        } catch (error){
            this.$swal({
                title: 'Speicher Fehler', text: error, icon: 'error',
                showConfirmButton: true,
                confirmButtonText: 'Online Speichern',
                showDenyButton: true,
                denyButtonText: 'Nein'

            }).then(e => {
                if(e.isConfirmed){
                    this.$socket.emit('edit-report-details', { token: this.token, report: { id: this.reportid.id, items: this.items }, formular: { id: this.id }, reloadAllReports: 0 })
                    next()
                }
            })
        }
    },  
    mounted(){
        const wrapper = this.$refs['formular-wrapper']
        if(wrapper){
            this.maxHeight = wrapper.clientHeight
        }
    },
    data(){
        return{
            scale: 3.5 ,
            editMode: false,
            editObject: {},
            filename: 'defaultReport',
            reportStats: [
                { name: 'Offen', value: 'open', key: this.$uuid.v4()},
                { name: 'Zur Abbrechung', value: 'for billing', key: this.$uuid.v4() },
                { name: 'Abgerechnet', value: 'billed', key: this.$uuid.v4() },

            ],
            items: [
             
            ],
            manualDownload: false,
            maxHeight: 0,
            formularData: {id: this.$props.id, name: this.$props.name},
            valueHasChanged: false,

            doNotSave: false,
            internalReportId: -1,
            internalReportKey: -1,

            startSendReport: false,
            reportToMail: '',
            
            reportViewLoaded: false,
        }
    },
    created(){
        const vm = this;
        this.unsubscribe = this.$store.subscribe((mutation) => {
            if (mutation.type == 'set-edit-mode' && !mutation.payload)
                this.$socket.emit('edit-formular-details', { token: this.token, formular: { id: this.id, name: this.formularData.name, items: this.items}})
            if (mutation.type == 'set-reload-formular') {
                console.log('set-reload-formular');
                const openReport = !((!vm.reportkey) || vm.reportkey == '-1' || vm.reportid == '');
                if(!openReport)
                    this.reloadFormular()
            }
        })

        // you may call unsubscribe to stop the subscription
    },
    beforeDestroy(){
        if(this.unsubscribe)
            this.unsubscribe()

       
    }
}
</script>
<style>




.vue-html2pdf.fomular-view .layout-container{
    position: relative !important;
    height: auto !important;
    width: unset !important;;
    background-color: unset !important;
    z-index: unset !important;

}


.md-toolbar.md-primary.md-theme-default.md-elevation-4 .md-inverted.md-field.md-theme-default > label{
    color: white;
}
.md-toolbar.md-primary.md-theme-default.md-elevation-4 .md-inverted.md-field.md-theme-default input{
    color: white !important;
}
.md-toolbar.md-primary.md-theme-default.md-elevation-4 .md-inverted.md-field.md-theme-default:after{
    background-color: white;
}
.md-toolbar.md-primary.md-theme-default.md-elevation-4 .md-inverted.md-field.md-highlight.md-theme-default:after {
    background-color: white;
}

#report-selector .md-list.md-theme-default{
    color: white;
    background-color: rgb(255, 4, 4) !important;
}

.md-list.md-theme-default:has( .md-in-toolbar){
    padding: 0;
}
.md-in-toolbar .md-menu-content-container{
    background-color: var(--md-theme-default-primary);
}
.md-in-toolbar .md-menu-content-container .md-list{
    padding: 0;
}
.md-in-toolbar.md-inverted button div span{
    color: white;
}
.md-toolbar.report-toolbar .md-field.md-theme-default.md-has-value .md-input{
    -webkit-text-fill-color:  white;;
}
.md-field.md-theme-default.md-has-value .md-input{
    -webkit-text-fill-color: black;
        ;
}
</style>