<template>
    <div class="vertical-wrapper">
        <h1>Dateien</h1>

        <div class="vertical-wrapper">
            <div style="margin: 5pt auto; flex: 0 0 auto; min-width: 300pt;">
                <md-field md-clearable>
                    <md-icon :md-src="require('../../assets/magnifying-glass-solid.svg')"></md-icon>
                    <label for="">Search</label>
                    <md-input v-model="searchString"></md-input>
                </md-field>
            </div>
            <div class="vertical-wrapper" style="overflow-y: scroll">
                <div class="horizontal-wrapper" style="flex: auto 0 0; margin: 0 20pt">
                    <md-switch class="md-primary" v-model="changeHistory">Änderungshistorie</md-switch>
                    <div></div>
                </div>
                <div v-if="changeHistory" class="vertical-wrapper" >
                    <file-item v-for="f in files.filter(e => e.name.includes(searchString))" :key="f.key" :name="f.name" v-on:file-restore="restoreReport"> </file-item>
                </div>
                <div class="horizontal-wrapper" style="flex: auto 0 0; margin: 0 20pt">
                    <md-switch class="md-primary" v-model="errorHistory">Fehler Historie</md-switch>
                    <div></div>

                </div>
                <div v-if="errorHistory" class="vertical-wrapper" >
                    <file-item v-for="f in jsonFiles" :key="f.key" :name="f.name" v-on:file-restore="restoreReport"> </file-item>
                </div>
            </div>
          
        </div>
    </div>
</template>
<script>
import fileItemVue from '../../components/file-item.vue'

export default {
    name: 'files-view',
    components: {
        'file-item':fileItemVue
    },
    methods: {
        deleteItem(file){
            this.$swal({
                icon: 'question',
                title: 'Delete File?',
                text: `Delete File with name ${file.name}.pdf?`,

                showDenyButton: true,
                showConfirmButton: true,

                denyButtonText: 'Nein',
                confirmButtonText: 'Ja',


            }).then(e => {
                if (e.isConfirmed) {
                    this.$socket.emit('delete-file', {token: this.token, file: file})
                }
            })
        },
        restoreReport(filename){
            fetch(`${this.$hostname}/files/restore`,  {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ token: this.token, filename: filename })  
            }).then(async e => {
                if(e.status == 200){
                    const {report , error } = await e.json()
                    if(report){
                        console.log(report);
                    }else if(error){
                          this.$swal({
                            icon: 'error',
                            title: 'Fehler',
                            text: error

                        })
                    }
                }
            })
        }
    },
    computed: {
        token() {
            return this.$store.getters.token
        },
    },
    sockets: {
        'set-file-list':function({ jsonFiles, files }){
            files.forEach(e => e.key = this.$uuid.v4())
            this.files = files

            jsonFiles.forEach(e => e.key = this.$uuid.v4())
            this.jsonFiles = jsonFiles

        },
        'set-file-src':function({file}){
            if(file.noFile){
                console.log('download failed');
                return
            }
            const linkSource = `data:application/pdf;base64,${file.src}`;
            const downloadLink = document.createElement("a");
            const fileName = `${file.name}.pdf`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
    },
    beforeRouteEnter(to, from, next) {

        next(vm => {
            vm.$socket.emit('get-file-list', {token: vm.token})
            next();
        })
    },
    data(){
        return{
            searchString: '',
            files: [],
            jsonFiles: [],

            changeHistory: false,
            errorHistory: false,

        }
    }
}
</script>