<template>
    <div>
        <md-tabs md-dynamic-height>
            <md-tab md-label="Auto Incement">
                <md-field>
                    <label for="">Increment Schema</label>
                    <md-input></md-input>
                </md-field>
            </md-tab>
            <md-tab md-label="JTL">

            </md-tab>
           
            <md-tab md-label="JS">
               <js-editor :autoFill="autoFill" v-on:save="e => $emit('save', e)"></js-editor>
                <md-button class="md-accent" v-on:click="clear">Remove Autofill</md-button>

            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import jsEditor from './js-editor.vue'
export default {
  components: { jsEditor },
    name: 'auto-fill-source',
    props: { autoFill: [Object, Number, Boolean]},
    methods:{
        async clear(){
            const {isConfirmed} = await this.$swal.fire({
                icon: 'question', 
                title: 'Sicher?', 
                text:'Auto Fill entfernen?',
                showConfirmButton: true,
                confirmButtonText: 'Ja',

                showDenyButton: true,
                denyButtonText: 'Nein',

            })
            if (isConfirmed)
                this.$emit('remove-autofill')
        }
    },  
    data(){
        return{
            httpUrl : '',
            httpMethod: 'post',
            httpBody: ''
        }
    }
}
</script>