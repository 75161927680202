<template>
    <div class="vertical-wrapper">
        <div style="flex:  1 0 0;" class="vertical-wrapper">

            <router-view ref="">

            </router-view>
        </div>

        <md-bottom-bar style="flex: 0 0 auto; z-index:10" class="footer" md-type="shift" :md-sync-route="true"
            :class="{'hide': hideButtomBar}" ref="bottom-navigator" :options="{}">

            <md-bottom-bar-item id="assignments" :to="{ name:'report-view'}" md-label="Aufträge">
                <md-icon class="md-bottom-bar-icon" :md-src="require('../assets/bullhorn-solid.svg')"></md-icon>
                <span class="md-bottom-bar-label">Reports</span>
                <i class="badge" v-if="assigments">{{ assigments }}</i>
            </md-bottom-bar-item>


            <md-bottom-bar-item id="user" :to="{ name:'files-view'}" md-label="Dateien"
                :md-icon="require('../assets/folder-open-solid.svg')"></md-bottom-bar-item>
            <md-bottom-bar-item v-if="isAdmin" id="formular" :to="{ name:'formular-list-view'}" md-label="Formulars"
                :md-icon="require('../assets/file-signature-solid.svg')"></md-bottom-bar-item>

            <md-bottom-bar-item id="user" :to="{name:'user-view'}" md-label="Benutzer"
                :md-icon="require('../assets/user-solid.svg')"></md-bottom-bar-item>

        </md-bottom-bar>
    </div>
</template> 
<script>
const lodashClonedeep = require("lodash.clonedeep");

export default {
    name: 'home-view',
    mounted(){
        this.$store.commit('set-footer-height', this.$refs['bottom-navigator'].$el.clientHeight)
    },
    computed: {
        token() {
            return this.$store.getters.token
        },
        isAdmin(){
            return this.$store.getters.isadmin
        },
        assigments(){
            return this.$store.getters.reports.filter(e => e.status == 'open').length
        },
        hideButtomBar() {
            return this.$route.name == 'report-details-view';
        },
        reports() {
            return this.$store.getters.reports
        }
    },
    sockets:{
        'set-report-list': function ({ reports }) {

            const authorObj = { 'Alle': 0}
            reports.forEach(r => {
                authorObj[r.author] = 0
            })
            this.$store.commit('set-report-authors', Object.keys(authorObj).map(e => { return { name: e, key: this.$uuid.v4() }} ) )


            /*Check if existing Reports are not synced */
            const bufferReports = lodashClonedeep(this.reports.filter(e => e.notSynced))
            this.$nextTick(()=>  {
                bufferReports.forEach((e, i) => {
                    e.notSynced = false;
                    this.$socket.emit('edit-report-details', { token: this.token, report: { id: e.id, items: e.items }, formular: { id: e.IDh_formular }, reloadAllReports:bufferReports.length == (i + 1) })
                })
                this.$store.commit('set-report-syncing', false)

            })
            
            
            reports.forEach(e => {e.key = this.$uuid.v4(); e.notSynced = false})

            this.$store.commit('set-reports', reports)
        },
        'set-formulars': function ({ formulars }) {
            formulars.forEach(e => e.key = this.$uuid.v4())
            this.$store.commit('set-formulars', formulars);

            formulars.forEach(f => {
                this.$socket.emit('get-formular-details', { token: this.token, formular: { id: f.id, name: f.name } })
            })

        },
        'set-formular-details': function ({ formular,  items }) {
            this.$mapItems(items, (e) => {
                e.value = e.value == 'table' ? [] : '';
                e.IDh_report_item = - 1;
                if (e.options && e.options.identifier != undefined) {
                    e.options.identifier = parseInt(e.options.identifier) ? true : false
                }
            })
            items.forEach(e => e.reachEndOfPaper = false)
            //console.log(formular, items);

            this.$store.commit('set-formular-items', { formular: formular, items: items })
            this.$store.commit('set-reload-formular', true)
        },

        'set-report-details': function ({ items, report, }) {

            items.forEach(e => e.reachEndOfPaper = false)

            this.$mapItems(items, (e) => {
                if (e.options && e.options.identifier != undefined)
                    e.options.identifier = parseInt(e.options.identifier) ? true : false
            })
            this.$store.commit('set-report-items', { report: report, items: items })
            //this.reportStatus = report.status
            this.$store.commit('set-report-status', report.status)
        },
    },
    data(){
        return{
        }
    }
}
</script>
<style>

  .badge {
      width: 19px;
      height: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 6px;
      right: 6px;
      background: #fff;
      border-radius: 100%;
      color: #000;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -.05em;
      font-family: 'Roboto Mono', monospace;
  }
</style>