<template>
    <div class="vertical-wrapper" style="overflow-y: scroll;">
        <div class="horizontal-wrapper" style="margin: 0 2.5%; flex: 0 0 auto;">
            <h1 style="flex: 1 0 0">User</h1>
            <md-button class="md-raised md-primary" v-on:click="logout">Logout</md-button>
        </div>


        <div v-if="online" style="margin: 2.5%">
            <div>
                <md-field>
                    <label for="">User</label>
                    <md-select v-model="userid">
                        <md-option v-for="u in users" :key="u.key" :value="u.id">{{u.name}}</md-option>
                        <md-option :value="-1">+</md-option>

                    </md-select>
                </md-field>
            </div>

            <div class="horizontal-wrapper">
                <md-field>
                    <label for="">Username</label>
                    <md-input v-model="selectedUser.name"></md-input>
                </md-field>
            </div>
            <md-switch class="md-primary" v-model="changePW">Change Password</md-switch>
            <div v-if="changePW || userid < 0">
                <md-field>
                    <label for="">Password</label>
                    <md-input type="password" v-model="pw0"></md-input>
                </md-field>
                <md-field>
                    <label for="">Password again</label>
                    <md-input type="password" v-model="pw1"></md-input>
                </md-field>
            </div>
            <div>
                <md-button class="md-primary md-raised" v-on:click="change">Change</md-button>
            </div>
        </div>
        <div v-else class="vertical-wrapper">
            <h1>Offline nicht verfügbar</h1>
            <div class="icon"
                :style="`background-image: url(${require('../../assets/lostConnection.png')}); flex: 1 0 200pt`">

            </div>
        </div>
        <div>
            <h4>Versionen</h4>
            <p>Back-End {{ version }}</p>
            <p>Front-End {{ fe_version }}</p>

        </div>
    </div>
</template>
<script>
export default {
    name: 'user-view',
    methods: {
        logout(){
            this.$store.commit('set-token', '')
            this.$router.push({name: 'login-view'})
        },
        change(){
            if (this.changePW && (this.pw0 == '' || this.pw0 != this.pw1)){
                this.$swal.fire({
                    icon: 'error',
                    title: 'Change user settings failed',
                    text: 'Passwords dont match'
                })
                return
            }
            this.$socket.emit('edit-user', { token: this.token, user: { id: this.userid, name: this.selectedUser.name, changePW: this.changePW, pw: this.pw0 }})
        },


    },
    sockets: {
        'set-user-list':function({users}){
            users.forEach(e => {e.key = this.$uuid.v4(); e.changePW = false; e.pw0 = ''; e.pw1 = '';})
            this.users = users
            if(this.users.length)
                this.userid = this.myUser? this.myUser.id: this.users[0].id

            //this.users.push({id: -1, name: '+', pw0: '', pw1: ''})
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        myUser(){
            return this.$store.getters.user
        },
        selectedUser(){
            return (this.userid && this.userid > 0)? this.users.find(e => e.id==this.userid) : {id: -1, name: '', pw0:'', pw1: ''}
        }, 
        online(){
            return this.$store.getters.online
        },
        changePW:{
            set(val){
                this.changePWValue = val
            },
            get(){
                return this.changePWValue || this.userid < 0
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.$socket.emit('get-user-list', { token: vm.token })

            fetch(`${vm.$hostname}/get-version`, {method: 'POST'}).then(e => {
                console.log((e.status));
                return e.text()
            }).then(e => {
                vm.version = e
            }).catch(e => {
                console.log(e);
            })
            next();
        })
    },
    data(){
        return{
            users: [],
            userid: 0,
            username : '',

            changePWValue: false,
            pw0:'', pw1: '',

            version: '',
            fe_version: '1.1.5'
        }
    }
}
</script>