
import Vue from 'vue'
import Vuex from 'vuex'

import md5 from 'md5'

Vue.use(Vuex)
function isObject(value) {
    return !(value instanceof Date) && !Array.isArray(value) && !Object.is(value, null) && !Object.is(value, undefined) && !(value instanceof Function)
}
const store = function createStore() {
    let userToken = Vue.prototype.$cookies.get('user-token')
    const getFromCookies = function(key, defaultValue){
        const cached = localStorage.getItem(key)
        if(cached)
            return isObject(defaultValue) || Array.isArray(defaultValue)? JSON.parse(cached) : cached
        return defaultValue
    }
    if(!userToken)
        userToken = ''

    const authors = {'Alle': 0 }
    const reports = getFromCookies('reports', []);
    reports.forEach(r => {
        r.author
        authors[r.author] = 0
    })

    const mapItems = Vue.prototype.$mapItems

    return new Vuex.Store({
        state: () => ({
            token: getFromCookies('user-token'),
            user: {id: -1, name: ''},
            admin: 0,

            editMode: false,
            online: false,

            reportSaved: false,
            footerHeight: 0,

            pdfGenerating:false,
            reloadFormular: false,

            formulars: getFromCookies('formulars', []),
            reports: getFromCookies('reports', []),

            reportSyncing: false,
            forceSync: false,

            reportStatus: '',
            comboFields: getFromCookies('combo-field', []),

            // convert from very easy group by function
            reportAuthors: Object.keys(authors).map((e, i) => { return {name: e, key: i} }),
            
            reportSelector: getFromCookies('report-selector', { author: 'Alle', formular: 'all', status: 'all', searchtext: ''})
        }),
        mutations: {
            'set-token': function (state, token) {
                Vue.prototype.$cookies.set('user-token', token)
                state.token = token;
                localStorage.setItem('user-token', token)

            },
            'set-admin': function (state, admin) {
                state.admin = admin
            },
            'set-edit-mode': function(state, editMode){
                state.editMode = editMode
            },
            'set-user': function (state, {id, name}){
                state.user.id = id
                if(name)
                    state.user.name = name
            },
            'set-online': function(state, payload){
                state.online = payload
            },
            'set-footer-height': function(state, height){
                state.footerHeight = height
            },
            
            'set-formulars': function(state, formulars){
                const currentStateFormulars = state.formulars;

                const formularBuffer = formulars.map(f => {
                    const m = currentStateFormulars.find(e => e.id==f.id)
                    if(m && m.items)
                        f.items = m.items
                    return f
                })
                state.formulars = formularBuffer
                localStorage.setItem('formulars', JSON.stringify(state.formulars))


            },
            'set-formular-items' : function(state, {formular, items}){
                const f = state.formulars.find(e => e.id==formular.id)
                if(f){
                    f.items = items
                }
                localStorage.setItem('formulars', JSON.stringify(state.formulars))

            },
            'set-reports' : function(state, reports){
                console.log('[STORE] set-reports')
                //const currentStateReports = state.reports

                // clear and push method for VUE Change Event to occure
                state.reports = []
                reports.forEach(r => {
                   
                    state.reports.push(r)
                })
                

                localStorage.setItem('reports', JSON.stringify(state.reports.filter(e => e.status == 'open')))

            },
            'set-report-items' :function(state, {report, items}){
                console.log('[STORE] set-report-items', report.key);
                const r = state.reports.find(e => e.key==report.key)
                if(r){
                    r.items = items
                    r.notSynced = report.notSynced;
                    

                    // extra fields
                    r.identifier = report.identifier;
                    r.author = report.author;
                    r.searchAttributes = report.searchAttributes;
                    
                    console.log('Override', r);

                }

                localStorage.setItem('reports', JSON.stringify(state.reports.filter(e => e.status == 'open')));

            },
            'save-report': function(state, {report, formular, items, newReportKey}){
                console.log('[Store] save report');
                // find existing report
                let report_match = state.reports.find(e => e.key == report.key)

                if (report_match) {// if report is found edit short cut variables
                    
                    mapItems(items, (item) => {
                        // find matching report item and override value
                        const m = report_match.items.find(e => e.IDh_form_item == item.id)
                        if(m){
                            m.type = item.type
                            m.value = item.value

                            if(m.type == 'combobox'){
                                m.options.items = item.options.items
                            }
                        }
                    });

                    report_match.identifier = report.keyIdetifier
                    report_match.notSynced = true
                    report_match.author = report.author;
                    report_match.status = report.state;

                    report_match.searchAttributes = report.searchAttributes.filter(e => e != '').join(', ')
                }else{// if report is not found add report to reports

                    // make item array flat
                    const itemsArray = []
                    mapItems(items, (element) => {
                        // non value elements
                        if(element.type == 'panel' || element.type == 'text' || element.type == 'image')
                            return

                        const options = {}
                        
                        element.options.identifier? options.identifier = 1 : 0
                        parseInt(element.options.searchAttribute) ? options.searchAttribute = 1 : 0
                        parseInt(element.options.authorField) ? options.authorField = 1 : 0

                        if (element.type == 'combobox'){
                            options.items = element.options.items;
                        }
                        itemsArray.push({
                            id: -1,
                            IDh_form_item: element.id,
                            type: element.type,
                            value: element.value,
                            options
                        })
                    })

                    const newReport = {
                        id: -1,
                        IDh_form: formular.id,
                        name: formular.name,
                        status: report.state,
                        key: newReportKey, 

                        searchAttributes: report.searchAttributes.join(', '),
                        identifier: report.keyIdetifier, 
                        notSynced: true,
                        author: report.author,


                        items: itemsArray
                    }
                    state.reports.push(newReport)
                }

                localStorage.setItem('reports', JSON.stringify(state.reports.filter(e => e.status == 'open')))


            },
            'set-report-sync-status': function(state, {reportId, status}){
                const m = state.reports.find(e => e.id == reportId)
                if(m)
                    m.notSynced = status
                
                localStorage.setItem('reports', JSON.stringify(state.reports.filter(e => e.status == 'open')))

            },
            'set-report-status':function(state, status){
                state.reportStatus = status
            },
           
            'set-combo-field': function (state, comboFields){
                state.comboFields = comboFields;

                const comboFieldsString = JSON.stringify(state.comboFields)
                localStorage.setItem('combo-field', comboFieldsString);

            },
            'set-report-authors': function(state, authors){
                state.reportAuthors = authors
            },
            'set-pdf-generating': function(state, pdfGenerating){
                state.pdfGenerating = pdfGenerating
            },
            'set-reload-formular': function(state, reloadFormular){
                state.reloadFormular = reloadFormular
            },
            'set-report-selector' : function(state, reportSelector){
                state.reportSelector = reportSelector;
                localStorage.setItem('report-selector', JSON.stringify(state.reportSelector))
            },

            'set-report-syncing': function(state, syncing){
                state.reportSyncing = syncing;
            }
        },
        actions: {
            'toggle-edit-mode': function ({ commit, state }) {
                commit('set-edit-mode', !state.editMode)
            },
            'reload-formular': function ({ commit, state }) {
                commit('set-reload-formular', !state.reloadFormular)
            },

            'sync-reports': function({ commit, state }){
                commit('set-report-syncing', true);

                // filter only changed reports should be uploaded
                const bufferReports = state.reports.filter(e => e.notSynced)
              

                // make sync request to the server
                fetch(`${Vue.prototype.$hostname}/reports/sync`, {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                    }, body: JSON.stringify({
                        token: state.token,
                        reports: bufferReports
                    })
                })
                .then(async (e) => {
                    console.log('[STORE] SYNC Feedack', e.status, e.headers.get('Content-Length') / (1024 * 1024));
                    if(e.status == 200){
                        const { reports, reportKeysHash, syncedReportKeys, error } = await e.json()
                        console.log(reports.length, reportKeysHash, syncedReportKeys, error);

                        if (syncedReportKeys){
                            syncedReportKeys.forEach(id => {
                                const m = state.reports.find(e => e.id == id)
                                if(m){
                                    m.notSynced = false
                                }
                            })

                        }

                        if(reports){
                            const localReportsKeysHash = md5(state.reports.map(e => e.id).join(' '))
                            console.log(state.forceSync , localReportsKeysHash , reportKeysHash);
                            // reports list is changed
                            if(state.forceSync || localReportsKeysHash != reportKeysHash){
                                reports.forEach(e => { 
                                    e.notSynced = false 
                                    if(!e.name)
                                        e.name = ''
                                })
                               
                                commit('set-reports', reports)
                            }else{

                                // only update all report items -> report head did not change

                                reports.forEach(report => {
                                    const m = state.reports.find(e => e.id == report.id)
                                    if(m.key == 'e4c3269c-cba9-41e9-977d-76c7cd757842')
                                        console.log(m.key, m.md5, report.md5, md5(m), report.items);

                                    if(m){
                                        if(m.md5 != report.md5){
                                            m.notSynced = false
                                            m.identifier = report.identifier;
                                            m.author = report.author;
                                            m.status = report.status;
                                            m.created = report.created;

                                            m.searchAttributes = report.searchAttributes;
                                            
                                            // update md5 code
                                            m.md5 = md5(m)

                                            console.log('set-report-items');
                                            commit('set-report-items', {report, items: report.items})
                                        }

                                    }
                                })
                             //   console.log(reports);
                            }
                        }else if(error){
                            console.log(`${Vue.prototype.$hostname}/reports/sync`);
                            console.log(error);
                        }
                        commit('set-report-syncing', false);

                    }
                    console.log('[STORE] SYNC Done');
                })
                .catch(e => {
                    console.log(e);
                })
            }
        },
        getters: {
            token(state) {
                return state.token;
            },
           
            isadmin(state) {
                return state.admin
            },
            editMode(state){
                return state.editMode
            },
            getUser(state){
                return state.user
            },
            online(state){
                return state.online
            },
            reportSaved(state){
                return state.reportSaved
            },
            footerHeight(state){
                return state.footerHeight
            },


            formulars(state){
                return state.formulars
            },
            formularItems: (state) => (id) =>{
                return state.formulars.find(e => e.id == id).items
            },
            reports(state){
                return state.reports
            },
            reportItems: (state) => (id) => {
                return state.reports.find(e => e.id == id).items
            },
            reportItemsbyKey: (state) => (key) => {
                const items = state.reports.find(e => e.key == key).items
                return items
            },
            reportIdByKey: (state) => (key) =>{
                const m = state.reports.find(e => e.key == key)
                if(m)
                    return m.id
                else
                    return -1
            } ,
            reportStatus(state){
                return state.reportStatus
            },

            getComboFields(state){
                return state.comboFields
            },
            getReportAuthors(state){
                return state.reportAuthors
            },

            getPdfGenerating(state){
                return state.pdfGenerating
            },
            getReloadFormular(state){
                return state.reloadFormular
            },
            getReportSelector(state){
                return state.reportSelector
            },
            getReportSyncing(state){
                return state.reportSyncing
            }
        }
    })
}
export default store;