<template>
    <div class="horizontal-wrapper" style="flex: 0 0 auto; margin: 5pt auto">
        <h3>{{ name }}</h3>
        <md-button v-on:click="$emit('file-restore', name)" class="md-icon-button"
            style="margin-top: 6pt">
            <md-icon :md-src="require('../assets/trash-arrow-up-solid.svg')"> </md-icon>

        </md-button>
        <md-button 
            class="md-icon-button" 
            style="margin-top: 6pt"
            v-on:click="$emit('file-delete', f)">
            <md-icon :md-src="require('../assets/trash-can-solid.svg')"> </md-icon>

        </md-button>
    </div>
</template>
<script>
export default {
    name: 'file-item',
    props: { name: String },

}
</script>