<template>
    <div v-if="editMode" class="vertical-wrapper" style="background-color: white;">
        <h3>Editor</h3>
        <md-tabs md-dynamic-height>
            <md-tab md-label="Items">
                <div v-if="(!Object.keys(editObject).length) || editObject.type == 'panel'">
                    <div v-for="(items, i) in editorAddItems" :key="i + $uuid.v4()" class="horizontal-wrapper">
                        <div v-for="(item, i) in items" :key="i + $uuid.v4()" class="vertical-wrapper editor-add-item"
                            draggable @dragstart.self="startDrag($event, item.text.toLowerCase())"
                            v-on:dblclick="$emit('add-item', item.text.toLowerCase())">
                            <div class="icon" :style="` background-image: url(${item.img})`">
                            </div>
                            <h4>{{item.text}}</h4>
                        </div>

                    </div>

                    <div v-if="editObject && editObject.type == 'panel'">
                        <h4>Layout</h4>
                        <md-field>
                            <label for="">orientation</label>
                            <md-select v-model="editObject.options.orientation">
                                <md-option :value="'horizontal'">horizontal</md-option>
                                <md-option :value="'vertical'">vertical</md-option>

                            </md-select>
                        </md-field>
                        <md-field>
                            <label for="">Size</label>
                            <md-select v-model="editObject.options.flexGrow">
                                <md-option v-for="(o, i) in ['auto', 1, 2, 3, 4, 5]" :key="i + $uuid.v4()" :value="o">
                                    {{o}}
                                </md-option>
                            </md-select>
                        </md-field>
                    </div>
                </div>
                <div v-else style="margin: auto 2pt">

                    <md-field>
                        <label for="">Name</label>
                        <md-input v-model="editObject.name" :value="editObject.name"></md-input>
                    </md-field>
                    <h4>Layout</h4>
                    <div class="horizontal-wrapper">
                        <md-field>
                            <label for="">Size</label>
                            <md-select v-model="editObject.options.flexGrow">
                                <md-option v-for="(o, i) in ['auto', 1, 2, 3, 4, 5]" :key="i + $uuid.v4()" :value="o">
                                    {{o}}
                                </md-option>
                            </md-select>
                        </md-field>

                        <md-switch>hide on PDF</md-switch>
                    </div>
                    <div class="vertical-wrapper" v-if="editObject.type == 'input'">
                        <md-switch class="md-primary" v-model="editObject.options.identifier">Identifier</md-switch>
                        <md-switch class="md-primary" v-model="editObject.options.authorField">Author</md-switch>
                        <md-switch class="md-primary" :class="{'hide': editObject.options.identifier}"
                            v-model="editObject.options.searchAttribute">search attribute</md-switch>

                        <md-switch class="md-primary" v-model="editObject.options.autoFillEabled">Auto Fill</md-switch>
                        <auto-fill-source :autoFill="editObject.options.autoFill" v-on:save="e => editObject.options.autoFill = e" v-on:remove-autofill="editObject.options.autoFill = 0"></auto-fill-source>

                    </div>
                    <div class="vertical-wrapper" v-if="editObject.type == 'text'">
                        <md-field>
                            <label for="">Header</label>
                            <md-select v-model="editObject.options.header">
                                <md-option value="h1">h1</md-option>
                                <md-option value="h2">h2</md-option>
                                <md-option value="h3">h3</md-option>
                                <md-option value="h4">h4</md-option>
                                <md-option value="h5">h5</md-option>

                            </md-select>
                        </md-field>
                        <md-field>

                            <label for="">textAlign</label>
                            <md-select v-model="editObject.options.textAlign">
                                <md-option value="left">left</md-option>
                                <md-option value="center">center</md-option>
                                <md-option value="right">right</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="vertical-wrapper" v-if="editObject.type == 'image'">
                        <md-field>
                            <label for="">URL</label>
                            <md-file v-model="editObject.options.image" accept="image/*" v-on:change="imageChange" />
                        </md-field>
                    </div>

                    <div class="vertical-wrapper" v-if="editObject.type == 'table'">
                        <h4>Columns</h4>
                        <md-table v-model="columns" style="flex: 0 0 auto">


                            <md-table-row slot="md-table-row" slot-scope="{item}"
                                v-on:click="openColumnEditor(item.id)">
                                <md-table-cell md-label="title">
                                    {{ item.title }}
                                </md-table-cell>
                                <md-table-cell md-label="type">
                                    {{item.type}}
                                </md-table-cell>
                            

                            </md-table-row>
                            <div class="horizontal-wrapper">
                                <div></div>
                                <md-button class="md-primary md-raised" v-on:click="addColumn">add</md-button>
                                <div></div>
                            </div>
                        </md-table>
                        <div>
                            <md-button class="md-primary md-raised" v-on:click="addColumn">add</md-button>
                        </div>
                    </div>

                    <div class=" vertical-wrapper" v-if="editObject.type == 'combobox'">
                        <md-switch class="md-primary" v-model="editObject.options.authorField">Author</md-switch>

                        <dropdown-source 
                        :list.sync="editObject.options.items" 
                        :url="editObject.options.url" 
                        :combofield-id.sync="editObject.options.IDh_combofield"
                        
                        :dropdown-type.sync="editObject.options.dropdownType"
                        >
                        </dropdown-source>
                    </div>
                    <div class=" vertical-wrapper" v-if="editObject.type == 'checkbox'">
                        <md-field>
                            <label for="">textAlign</label>
                            <md-select v-model="editObject.options.textAlign">
                                <md-option value="left">left</md-option>
                                <md-option value="center">center</md-option>
                                <md-option value="right">right</md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class=" vertical-wrapper" v-if="editObject.type == 'datetime'">
                        <md-field>
                            <label for="">Format</label>
                            <md-select v-model="editObject.options.format">
                                <md-option value="datetime">Datum & Uhrzeit</md-option>
                                <md-option value="date">Datum</md-option>
                                <md-option value="time">Uhrzeit</md-option>
                            </md-select>
                        </md-field>

                        <md-field>
                            <label for="">textAlign</label>
                            <md-select v-model="editObject.options.textAlign">
                                <md-option value="left">left</md-option>
                                <md-option value="center">center</md-option>
                                <md-option value="right">right</md-option>
                            </md-select>
                        </md-field>
                    </div>

                </div>
                <div v-if="Object.keys(editObject).length">
                    <md-button class="md-accent md-raised" v-on:click="removeItem">remove</md-button>
                </div>
            </md-tab>
            <md-tab md-label="General">
                <div class="vertical-wrapper">
                    <md-field>
                        <label for="">Formular Name</label>
                        <md-input v-model="formular.name"></md-input>
                    </md-field>
                    <div>
                        <md-button class="md-raised md-primary" v-on:click="copyForm">Duplizieren</md-button>
                    </div>
                    <div style="flex: 0 0 100pt"></div>
                    <div>
                        <md-button class="md-raised md-accent" v-on:click="deleteForm">DELETE Formular</md-button>
                    </div>
                </div>
            </md-tab>
        </md-tabs>

        <column-editor-dialog :open="showColumnEditor" v-on:close="showColumnEditor = false" :column="currentColumn"
            v-on:save-column="saveColumn" v-on:delete-column="deleteColumn" v-on:change-order="changeColumnOrder">
        </column-editor-dialog>
    </div>
</template>
<script>
import autoFillSourceVue from './auto-fill-source.vue';
import columnEditorDialogVue from './column-editor-dialog.vue'
import dropdownSourceVue from './dropdown-source.vue';

export default {
    name : 'editor',
    props: { 'edit-object': Object, 'formular':Object},
    components: {'column-editor-dialog':columnEditorDialogVue, 'dropdown-source':dropdownSourceVue, 'auto-fill-source':autoFillSourceVue},
    methods: {
        imageChange(e) {

            if (!e.target.files.length)
                return;
            const file = e.target.files[0]
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                const png = event.target.result;
                this.editObject.options.url = png
            });
            reader.readAsDataURL(file);
        },

        openColumnEditor(id){
            this.currentColumn = this.columns.find(e => e.id == id)
            this.showColumnEditor = true;
        },
        startDrag(evt, type){
            //this.$emit('add-')

            evt.dataTransfer.dropEffect = 'move'
            evt.dataTransfer.effectAllowed = 'move'
            evt.dataTransfer.setData('dataKey', 'new-key/' + type)
        },
        removeItem(){
            this.$swal.fire({
                icon: 'warning',
                title: 'Delete Item?',
                text: 'Are you sure to remove Item',
                showConfirmButton: true,
                confirmButtonText: 'Yes',

                showDenyButton: true,
                denyButtonText: 'No'
            }).then(e => {
                if(e.isConfirmed)
                    this.$emit('delete-item', this.editObject)
            })
        }, 
        saveColumn(){
            if (this.editObject.type == 'table' && !this.columns.some(e => e.key == this.currentColumn.key))
                this.columns.push(this.currentColumn)

            this.editObject.redraw = true
            this.showColumnEditor = false;
            this.editObject.options.columns = this.columns
        },
        addColumn(){
            this.currentColumn = { id: this.columns.length, name: '', key: this.$uuid.v4(), type: 'text', flexWidth: 1 }
            this.showColumnEditor = true;
        },
        deleteColumn(column){
            if(this.editObject.type == 'table')
                this.columns = this.columns.filter(e => e.key != column.key) 
            this.showColumnEditor = false

            this.editObject.redraw = true
            this.editObject.options.columns = this.columns
        },
        copyForm(){
            this.$swal.fire({
                icon: 'question',
                title: 'Formular duplizieren?',
                text: `Wie möchtest du das Formular nennen?`,
                
                input: 'text',
                inputPlaceholder: 'neues Formular',

                showConfirmButton: true,
                confirmButtonText: 'Erstellen',


                showDenyButton: true,
                denyButtonText: 'Abbrechen'
            }).then(e => {
                if (e.isConfirmed && e.value != '')
                    this.$emit('copy-formular', {name: e.value})
            })  
        },
        deleteForm(){
            this.$swal.fire({
                icon: 'warning',
                title: 'Delete Formular?',
                text: `Are you sure to delete ${this.formular.name}`,
                showConfirmButton: true,
                confirmButtonText: 'Yes',

                showDenyButton: true,
                denyButtonText: 'No'
            }).then(e => {
                if (e.isConfirmed)
                    this.$emit('delete-formular')
            }) 
        },
        changeColumnOrder({c, n}){
            const cOrder = c.order 
            const newIndex = c.id + n
            if (newIndex < 0 || newIndex >= this.columns.length)
                return
            const nOrder = this.columns[newIndex].order
            this.columns[newIndex].order = cOrder
            c.order = nOrder;
        }
    },
    computed:{
        editMode(){
            return this.$store.getters.editMode
        },
        columns:{
            get(){
                const columns = typeof this.editObject.options.columns == 'string' ? JSON.parse(this.editObject.options.columns) : this.editObject.options.columns
                columns.forEach((e, i) => { e.id = i; e.key = !e.key? this.$uuid.v4(): e.key})
                return columns
            },
            set(val){
                this.editObject.options.columns = val
            }
        }
    },
    data(){
        return{
            currentColumn: {},
            showColumnEditor: false,
            editorAddItems: [
                [

                    { text: 'Panel', img: require('../assets/table-columns-solid.svg') },
                ],[
                    { text: 'Text', img: require('../assets/heading-solid.svg') },
                    { text: 'Image', img: require('../assets/image-solid.svg') },
                    { text: 'Input', img: require('../assets/minus-solid.svg') },

                ],[
                    { text: 'TextArea', img: require('../assets/align-justify-solid.svg') },
                    { text: 'DateTime', img: require('../assets/calendar-days-solid.svg') },
                    { text: 'Table', img: require('../assets/table-solid.svg') },
                    
                ],[
                    { text: 'Combobox', img: require('../assets/list-solid.svg') },
                    { text: 'Checkbox', img: require('../assets/check-solid.svg') },
                    { text: 'Signature', img: require('../assets/signature-solid.svg') },
                ],[
                    
                ]
            ],
          
        }
    }
}
</script>
<style>
.editor-add-item{
    border: 2pt solid darkgray;
    margin: 5pt;
    padding: 2.5pt 0;
    cursor: pointer;
}
.editor-add-item h4{
    margin-bottom: 0;
}
.editor-add-item .icon{
    flex: 0 0 30pt
}
</style>