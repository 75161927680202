<template>
    <div class="horizontal-wrapper" style="overflow-y: hidden" >
        
        <div class="vertical-wrapper navigation" :class="{ 'hide': editMode }" :style="`flex: 1 0 0; position:sticky;`">
            <h3 v-if="!formulars.length">Keine Formulare vorhanden</h3>
            <router-link class="nav-item" v-for="f in formulars" :key="f.key"
                :to="{name:'formular-view', params:{id:f.id, name:f.name, reportid:''}}">{{f.name}}</router-link>

            <div>
                <md-button class="md-raised md-primary" v-on:click="addFormular">add</md-button>
            </div>
        </div>
        <div  style="flex: 4 0 0; ">
            <router-view></router-view>
            <md-button class="md-fab md-plain md-primary vertical-wrapper"
                style="position: absolute;right: 10px; bottom: 70px;">
                <img class="icon" :src="require('../../assets/pen-solid.svg')"
                    :style="`height: 60%; width: 60%; margin: 20%`" v-on:click="enterEditMode" />
            </md-button>
        </div>

    </div>
</template>
<script>
export default {
    name: 'formular-list-view',
    methods:{
        addFormular(){
            this.$swal.fire({
                icon: 'question',
                title: 'Formuar erstellen',
                input: 'text',
                inputPlaceholder: 'neues Formular',
                text: 'Formular Name',
                showDenyButton: true,
                showConfirmButton: true,

            }).then(e => {
                console.log(e.isConfirmed && e.value && e.value !== '');
                if (e.isConfirmed && e.value && e.value !== ''){
                    this.$socket.emit('edit-formulars', { token: this.token, forms: [...this.formulars, { id: -1, key: this.$uuid.v4(), name: e.value }]})
                }
            })
        },
        enterEditMode(){
            if(!this.online){
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Warning',
                    text: 'You can enter Editor Mode only if you are online'
                })
            }
            this.$store.dispatch('toggle-edit-mode')
        }
    },
    computed:{
        token(){
            return this.$store.getters.token
        },
        editMode(){
            return this.$store.getters.editMode
        },
        formulars(){
            return this.$store.getters.formulars.filter(e => !e.deleted)
        },
        online(){
            return this.$store.getters.online
        }
    },
    sockets: {
       
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if(vm.editMode)
                vm.$store.dispatch('toggle-edit-mode')

            vm.$socket.emit('get-formulars', {token: vm.token})
            next();
        })
    },
    mounted(){
    },
    data(){
        return {
        }
    }
    
}
</script>
<style>

.navigation {
    background-color: white;
}

.navigation .nav-item {
    text-decoration: none;
    font-size: 14pt;
    padding: 10pt;

    color: var(--md-theme-default-primary-on-background, #448aff);
    background-color: white;
}

.navigation .nav-item.router-link-active {
    text-decoration: none;
    color: white !important;
    background-color: var(--md-theme-default-primary-on-background, #448aff);

}
</style>