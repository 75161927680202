<template>
    <md-dialog :md-active="open" v-on:md-closed="$emit('close')" v-on:md-clicked-outside="$emit('close')">
        <md-dialog-title>Column Editor</md-dialog-title>

        <div class="content vertical-wrapper">
            <div class="horizontal-wrapper" style="margin: 15pt">
                <md-field style="margin: 0 10pt">
                    <label for="">Name</label>
                    <md-input v-model="column.title"></md-input>
                </md-field>
                <md-field style="margin: 0 10pt">
                    <label for="">Type</label>
                    <md-select v-model="column.type" v-on:md-selected="columnTypeChanged">
                        <md-option v-for="(item, i) in columnsTypes" :key="i + $uuid.v4()" :value="item">{{item}}
                        </md-option>
                    </md-select>
                </md-field>
                <md-field style="margin: 0 10pt">
                    <label for="">width</label>
                    <md-input type="numeric" v-model="column.flexWidth"></md-input>

                </md-field>
              
                <div>
                    <md-button class="md-raised md-accent" v-on:click="deleteColumn">DELETE</md-button>
                </div>
            </div>
            <div class="horizontal-wrapper" style="margin: 15pt">
                <div class="vertical-wrapper" style="flex: 0 0 0;display: none; ">
                    <md-button class="md-primary md-raised" v-on:click="$emit('change-order', {c:column, n: -1})" style="margin-left: 0">up</md-button>
                    <md-button class="md-primary md-raised" v-on:click="$emit('change-order', { c: column, n: 1 })">down</md-button>
                </div>
                <md-field style="flex: 4 0 0 ">
                    <label for="">Text Align</label>
                    <md-select v-model="column.align">
                        <md-option :value="'left'">left</md-option>
                        <md-option :value="'center'">center</md-option>
                        <md-option :value="'right'">right</md-option>
                    </md-select>
                </md-field>
            </div>
           
            <div class="horizontal-wrapper" style="margin: 15pt">
                
                <md-field style="margin: 0 10pt">
                    <label for="">default</label>
                    <md-input></md-input>
                </md-field>
            </div>
            <div v-if="showDropDownOptions">
                <dropdown-source :list.sync="column.source" :url="''" :combofield-id.sync="column.IDh_combofield" :dropdown-type.sync="column.dropdownSource"></dropdown-source>
            </div>
        </div>
        <md-dialog-actions>
            <md-button class="md-primary" @click="$emit('close')">Close</md-button>
            <md-button class="md-primary" @click="saveColumns()">Save</md-button>
        </md-dialog-actions>
    </md-dialog>
</template>
<script>
import dropdownSourceVue from './dropdown-source.vue'
export default {
    name: 'column-editor-dialog',
    components: {'dropdown-source':dropdownSourceVue},
    props: {'open':Boolean, 'column':Object},
    methods: {
        saveColumns(){
            this.$emit('save-column')
        },
        deleteColumn(){
            this.$swal.fire({
                icon: 'warning',
                title: 'Delete Item?',
                text: 'Are you sure to remove Item',
                showConfirmButton: true,
                confirmButtonText: 'Yes',

                showDenyButton: true,
                denyButtonText: 'No'
            }).then(e => {
                if (e.isConfirmed)
                    this.$emit('delete-column', this.column)
            })
        },
        columnTypeChanged(){
            if(this.column.type == 'dropdown'){
                this.column.source = []
                this.$nextTick(() => {
                    this.showDropDownOptions = true
                })
            }
            else{
                this.showDropDownOptions = false

            }
        }
    },
    watch:{
        open(){
            if(this.open){
                this.showDropDownOptions = this.column.type == 'dropdown'
            }
        }
    },  
    data(){
        return{
            columnsTypes: [
                'text', 'numeric', 'dropdown', 'checkbox', 'date'
            ],
            list: [],
            showDropDownOptions: this.$props.column.type == 'dropdown'
        }
    }
}
</script>