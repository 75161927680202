import Vue from 'vue'
import App from './App.vue'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial);
Vue.use(require('vue-moment'));
import UUID from "vue-uuid";

Vue.use(UUID);


//Socket.io
import IO from 'socket.io-client'
import VueSocketIO from 'vue-socket.io'
const inProduction = !(process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test' && typeof console !== 'undefined')
//const devServer = 'https://onlinedocs24.frappgmbh.de'
const devServer = 'http://localhost:6500'

const optionsVueIO = {
  connection: inProduction ? IO() : IO(devServer)
}
Vue.prototype.$hostname = inProduction? '': devServer;

Vue.use(new VueSocketIO(optionsVueIO));

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('30d')

import createStore from '@/store/index.js'

import VueRouter from 'vue-router'
import myrouter from '@/router/index.js'
Vue.use(VueRouter)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
import VueHtml2pdf from 'vue-html2pdf'
Vue.use(VueHtml2pdf)

import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);

import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
Vue.component('datetime', Datetime);

Vue.config.productionTip = false

import './assets/layout.css'
import './registerServiceWorker'

Vue.prototype.$mapItems = function(items, cb){
  items.forEach(e => {
    cb(e)
    if (e.type == 'panel') {
      Vue.prototype.$mapItems(e.items, cb)
    }
  })
}

new Vue({
  render: h => h(App),
  store: createStore(),
  router: myrouter,
}).$mount('#app')
